/* http://http://daemonite.github.io/material/docs/4.1/material/selection-controls/#switches */

import React from "react";

function RadioSwitch({ label, id, validation, valid_feedback, invalid_feedback }) {
	return (
		<div id={id} className="custom-control custom-switch">
			<input className="custom-control-input" id={validation ? `customSwitchValidation` : `customSwitch`} type="checkbox" />
			<span className="custom-control-track"></span>
			<label className="custom-control-label" htmlFor={validation ? `customSwitchValidation` : `customSwitch`}>
				{label}
			</label>
			{validation && (
				<>
					<div className="invalid-feedback">{invalid_feedback}</div>
					<div className="valid-feedback">{valid_feedback}</div>
				</>
			)}
		</div>
	);
}

export default RadioSwitch;
