import React from "react";
import { NavLink } from "react-router-dom";
import useAuth from "../../../_hooks/useAuth";
import { SearchBar, UserProfile } from "../../MaterialsUI";
import { Drawer, ThemeSelector, DrawerContent } from "../../UI";
import useTheme from "../../../_hooks/useTheme";

function UserNavBar() {
	const { currentUser } = useAuth();
	const { isNightTheme } = useTheme();
	const activeClassName = "active";

	return (
		<>
			{/* Nav Toggler */}
			<header className="navbar figma-navbar d-flex align-content-center">
				<button
					className="navbar-toggler text-white"
					type="button"
					data-toggle="navdrawer"
					data-target="#navdrawer_default"
					// data-type="temporary"
					aria-controls="navdrawer_default"
					aria-expanded="false"
					aria-label="Toggle navigation">
					<span className="navbar-toggler-icon"></span>
				</button>
				<UserProfile className="mr-auto ml-2 text-white" user={currentUser} bg_transparent />
				<SearchBar
					placeholder="Search"
					className="text-white"
					input_className={`placeholder-dark rounded-input ${!isNightTheme && "bg-white"}`}
				/>
			</header>
			{/* The Drawer */}
			<Drawer
				nav_id="navdrawer_default"
				header={<span className="navbar-brand justify-content-center d-flex px-0">Arjuna</span>}
				sub_header="Support"
				sub_to_bottom
				sub_children={
					<>
						<ThemeSelector />
						<li className="nav-item">
							<NavLink className={({ isActive }) => `nav-link disabled ${isActive && activeClassName}`} to="#">
								<i className="material-icons mr-3">alarm_off</i> Contact
							</NavLink>
						</li>
						<li className="nav-item">
							<NavLink className={({ isActive }) => `nav-link disabled ${isActive && activeClassName}`} to="#">
								<i className="material-icons mr-3">link</i> Help
							</NavLink>
						</li>
					</>
				}>
				<UserProfile className="justify-content-center d-flex text mr-auto ml-2 bg-transparent" user={currentUser} />
				<DrawerContent />
			</Drawer>
		</>
	);
}

export default React.memo(UserNavBar);
