import React, { createContext, useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { addToLocalStorage, hasher } from "../_helpers/functions";
import { remoteBase } from "../_lib/config";
import { AppConstants } from "../_lib/constants";

const initialAuthState = {
	loading: true,
	currentUser: null,
};

// Auth data store
const AuthContext = createContext(initialAuthState);

export function AuthProvider({ children }) {
	const LS = JSON.parse(localStorage.getItem("LoggedInToken"));
	const [currentUser, setCurrentUser] = useState(LS);
	const [loading, setLoading] = useState(true);
	const [loginError, setLoginError] = useState(false);
	const [error, setError] = useState(false);
	const [myInterval, setMyInterval] = useState();
	// eslint-disable-next-line no-undef
	const activeRefresh = AppConstants.activeRefresh;
	const navigate = useNavigate();

	function logout() {
		localStorage.removeItem("LoggedInToken");
		setCurrentUser(null);
		clearInterval(myInterval);
		setMyInterval(null);
		navigate(`${AppConstants.publicUrl}/login`);
	}

	async function fetchRefresh() {
		let options = {
			method: "POST",
		};

		try {
			let res = await fetch(`${remoteBase}/users/refresh`, options);

			if (res.status === 401) return setLoginError(true), logout();

			if (!res.ok) return setLoginError(true), logout();

			addToLocalStorage("LoggedInToken", "timestamp", Date.now());
		} catch (error) {
			setError(true);
		}
	}

	function startSessionRefreshTimer() {
		let sessionInterval = setInterval(() => {
			activeRefresh === "true" && fetchRefresh();
		}, AppConstants.sessionTimeout);
		setMyInterval(sessionInterval);
	}

	async function login(credentials) {
		if (!credentials.email) return;
		const email = credentials.email.split("@");

		const name = email[0];
		const { color1, color2, color3, color4 } = hasher(name);
		const gradient = `#${color1}${color2}, #${color3}${color4}`;

		// Create user object
		let user = {
			gradient,
			name,
			isValid: false,
			timestamp: Date.now(),
		};

		const reqOptions = {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify(credentials),
		};

		try {
			let res = await fetch(`${remoteBase}/users/authenticate`, reqOptions);

			if (res.status === 401) return setLoginError(true), logout();

			if (!res.ok) return setLoginError(true), logout();

			user.isValid = true;
			localStorage.setItem("LoggedInToken", JSON.stringify(user));
			setCurrentUser(user);
			activeRefresh === "true" && startSessionRefreshTimer();
		} catch (error) {
			setError(true);
		}
	}

	function createAccount(user) {
		setCurrentUser(user);
		localStorage.setItem("LoggedInToken", JSON.stringify(user));
	}

	useEffect(() => {
		if (LS) {
			setCurrentUser(LS);
		}
		setLoading(false);
	}, []);

	useEffect(() => {
		if (!myInterval && currentUser?.isValid && activeRefresh === "true") {
			startSessionRefreshTimer();
		}
		return () => {
			clearInterval(myInterval);
		};
	}, []);

	useEffect(() => {
		if (!currentUser?.isValid) {
			logout();
		}
	}, [currentUser, localStorage]);

	// Exports functions and states of auth
	const authData = {
		currentUser,
		login,
		loginError,
		error,
		logout,
		createAccount,
		myInterval,
		startSessionRefreshTimer,
		fetchRefresh,
	};

	return <AuthContext.Provider value={authData}>{!loading && children}</AuthContext.Provider>;
}

const useAuth = () => useContext(AuthContext);

export default useAuth;
