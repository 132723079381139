/* eslint-disable no-case-declarations */
export const graphTreeInitialState = {
	currentItem: [],
};

export const learningPathInitialState = {
	currentItem: [],
};

export function treeReducer(state, action) {
	switch (action.type) {
		case "OPEN":
			const newItem = state.currentItem.indexOf(action.name) === -1 ? action.name : null;
			return { currentItem: [...state.currentItem, newItem] };

		case "CLOSE":
			const indexD = state.currentItem.indexOf(action.item);
			return {
				currentItem: state.currentItem.filter((_, index) => index !== indexD),
			};
		default:
			return state;
	}
}
