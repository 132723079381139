/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { Modal } from "../../MaterialsUI";
import useTheme from "../../../_hooks/useTheme";
import { useCompetencies } from "../../../_hooks/useCompetencies";
import DrawerContent from "./DrawerContent";
import { CompetencyEditor, LessonHeaderCompetencyBtn } from "../../Competencies";
import { LessonHeaderStandardAlignmentBtns } from "../../Standards";
import NewCompetencyForm from "../../Competencies/Forms/NewCompetencyForm";

function LessonHeader({ page_name }) {
	const { data } = useCompetencies();
	const [show, setShow] = useState(false);
	const { theme } = useTheme();

	return (
		<>
			<header className="border-bottom d-flex justify-content-between bg-light-themed w-full">
				<div className="d-flex justify-content-start px-2 pt-2">
					<span
						className={`text-xl ${theme === "night" ? "text-white" : "color-purple"} line-height-none cursor-default font-bold`}>
						{page_name}
					</span>
					{page_name == "Standards Alignment" ? (
						<LessonHeaderStandardAlignmentBtns />
					) : (
						<LessonHeaderCompetencyBtn data={data?.pages} show={show} setShow={setShow} />
					)}
				</div>
				<DrawerContent tab />
			</header>
			<Modal show={show}>
				<NewCompetencyForm show={show} setShow={() => setShow(false)} />
			</Modal>
		</>
	);
}

export default React.memo(LessonHeader);
