import React from "react";
import { Routes as Switch, Route } from "react-router-dom";

/* _components */
import RouteProtection from "./RouteProtection";

/* _layouts */
import Authoring from "../_layouts/AuthoringLayout";
import Login from "../_layouts/LoginLayout";
import NotFound from "../_layouts/NotFoundLayout";
import StandardsAlignmentLayout from "../_layouts/StandardsAlignmentLayout";
import { AppConstants } from "../_lib/constants";
import useAuth from "../_hooks/useAuth";

export default function Routes() {
	const rootRoute = AppConstants.publicUrl;
	const { currentUser } = useAuth();

	return (
		<Switch>
			<Route path="*" element={<NotFound />} />
			<Route element={<RouteProtection redirectPath={`${rootRoute}/login`} isAllowed={currentUser?.isValid} />}>
				<Route path={`${rootRoute}/`} element={<Authoring />} />
				<Route path={`${rootRoute}/standards`} element={<StandardsAlignmentLayout />} />
			</Route>
			<Route element={<RouteProtection redirectPath={`${rootRoute}/`} isAllowed={!currentUser?.isValid} />}>
				<Route path={`${rootRoute}/login`} element={<Login />} />
			</Route>
		</Switch>
	);
}
