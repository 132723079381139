import React from "react";
import { Button } from "../../MaterialsUI";

function ConfirmOrCancelModal({ children, className, onClickCancel, onClickConfirm, label }) {
	return (
		<div className={`d-flex flex-column align-content-center small-modal text ${className}`}>
			{label && <div className="h3 d-flex justify-content-center">{label}</div>}
			<div>{children}</div>
			<div className="d-flex justify-content-center py-3">
				<Button className="rounded-input btn-white mb-3 mr-5 bg-white" id="liveToastBtn" onClick={onClickConfirm}>
					<i className="material-icons">warning</i> Confirm
				</Button>
				<Button className="rounded-input btn-white mb-3 bg-white" onClick={onClickCancel}>
					Cancel
				</Button>
			</div>
		</div>
	);
}

export default ConfirmOrCancelModal;
