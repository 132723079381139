import React, { useState, createContext } from "react";

const LearningPathContext = createContext([{}, () => {}]);

const LearningPathProvider = ({ children }) => {
	// Currently hardcoded
	const [state, setState] = useState({ name: "Chapter 1" });

	return <LearningPathContext.Provider value={[state, setState]}>{children}</LearningPathContext.Provider>;
};

export { LearningPathContext, LearningPathProvider };
