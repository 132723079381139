import React, { createContext, useContext, useState, useEffect } from "react";

const initialTheme = {
	theme: "sunset",
};

const ThemeContext = createContext(initialTheme);

export function ThemeProvider({ children }) {
	const [theme, setTheme] = useState("sunset");
	const isNightTheme = theme === "night";
	const isLightTheme = theme === "light";
	const isSunsetTheme = theme === "sunset";

	function toggleTheme() {
		const activeTheme = isNightTheme ? "light" : "night";
		setTheme(activeTheme);
		document.documentElement.classList.add("theme-transition");
		document.documentElement.setAttribute("data-theme", activeTheme);
		localStorage.setItem("theme", activeTheme);
		window.setTimeout(function () {
			document.documentElement.classList.remove("theme-transition");
		}, 1000);
	}

	function selectTheme(selectedTheme) {
		setTheme(selectedTheme);
		document.documentElement.classList.add("theme-transition");
		document.documentElement.setAttribute("data-theme", selectedTheme);
		localStorage.setItem("theme", selectedTheme);
		window.setTimeout(function () {
			document.documentElement.classList.remove("theme-transition");
		}, 1000);
	}

	useEffect(() => {
		const activeTheme = localStorage.getItem("theme");
		if (!activeTheme) {
			localStorage.setItem("theme", theme);
		}
		document.documentElement.setAttribute("data-theme", activeTheme);
		setTheme(activeTheme);
	}, []);

	// Exports functions and states of theme
	const themeValue = {
		theme,
		isNightTheme,
		isLightTheme,
		isSunsetTheme,
		selectTheme,
		setTheme,
		toggleTheme,
	};

	return <ThemeContext.Provider value={themeValue}>{children}</ThemeContext.Provider>;
}

const useTheme = () => useContext(ThemeContext);

export default useTheme;
