import { HttpRequestException } from "../_errors_/httpRequestException";

export function removeBackdrop() {
	const backdrop = document.getElementsByClassName("navdrawer-backdrop")[0];
	if (!!backdrop) {
		backdrop.remove();
	}
}

export function removeBlankDrawer() {
	const drawer = document.getElementsByClassName("navdrawer-open-temporary")[0];
	if (!!drawer) {
		drawer.classList.remove("navdrawer-open-temporary");
	}
}

export function cleanClassNames() {
	const el = document.getElementsByClassName("false");
	for (let i = 0; i < el.length; i++) {
		el[i].classList.remove("false");
		i--;
	}
	const element = document.getElementsByClassName("undefined");
	for (let i = 0; i < element.length; i++) {
		element[i].classList.remove("undefined");
		i--;
	}
}

export function CompetencyArrayContainsAnotherArray(arr, targetArr) {
	if (!Array.isArray(arr)) return false;
	if (!Array.isArray(targetArr)) return false;

	return arr.every((v) => targetArr.includes(v));
}

/**
 * Removes a given value from an array.
 * @param {*} arr Array to remove value from - objects must contain a KEY
 * @param {*} value Value to remove from array - object must contain a KEY
 * @param {*} comparator OPTIONAL custom comparator function
 * @returns the filtered array
 */
export function removeValueFromArray(arr, value, comparator) {
	if (!value) return null;
	if (!comparator) comparator = (el) => el.Key !== value.Key;
	return arr.filter(comparator);
}

/**
 * Determines whether the given array of competencies contains a given competency.
 * @param {CompetencyKeyDesc[]} array array of competencies (object props: Key & Description)
 * @param {CompetencyKeyDesc} comp competency to check for
 * @returns boolean
 */
export function containsCompetency(array, comp) {
	let containsCompetency = false;
	let keysArray = [];

	array.forEach((item) => {
		keysArray.push(item.Key);
	});
	if (keysArray.includes(comp.Key)) containsCompetency = true;

	return containsCompetency;
}

export function differenceInArrays(arr1, arr2) {
	var a = [],
		diff = [];
	for (var i = 0; i < arr1.length; i++) {
		a[arr1[i]] = true;
	}
	for (i = 0; i < arr2.length; i++) {
		if (a[arr2[i]]) {
			delete a[arr2[i]];
		} else {
			a[arr2[i]] = true;
		}
	}
	for (var k in a) {
		diff.push(k);
	}
	return diff;
}

export function getTitleFromTuple(tuple, language = "en-US") {
	if (!tuple?.Title?.length) return null;

	for (let title of tuple.Title) {
		if (title.Language == language) return title;
	}
	return null;
}

export function getDefinitionFromTuple(tuple, language = "en-US") {
	if (!tuple?.Definition?.length) return null;

	for (let definition of tuple.Definition) {
		if (definition.Language == language) return definition;
	}
	return null;
}

export function getParent(data, key, competencies = true) {
	if (!data) return null;

	if (!Array.isArray(data)) data = [data];

	if (competencies) {
		for (let competency of data) {
			if (!competency?.Children?.length) continue;

			//check competency's descendants
			let found = competency.Children.find((c) => c.Key == key);

			if (found) return competency;

			//push down one level and search
			let parentCompetency = getParent(competency.Children, key);

			if (parentCompetency) {
				return parentCompetency;
			}
		}
	} else {
		for (let bit of data) {
			if (!bit?.Children?.length) continue;

			//check data's descendants
			let found = bit.Children.find((c) => c.Id == key);

			if (found) return bit;

			//push down one level and search
			let parentBit = getParent(bit.Children, key, false);

			if (parentBit) {
				return parentBit;
			}
		}
	}

	return null;
}

export function getAncestors(data, key, limit, isCompetencies = true) {
	let ancestors = [],
		currentNode = { Key: key },
		found,
		exhausted = false,
		count = 0;

	while (count < limit && !exhausted) {
		//find parents while we can
		found = getParent(data, currentNode.Key, isCompetencies);
		//parent is found
		if (found) {
			ancestors.push(found);
			//step up the tree
			currentNode = found;
			count++;
		}
		//uwu! no parent! break
		else exhausted = true;
	}
	return ancestors;
}

/**
 * legacy recursive lookup used by LearningPath component to determine parent data object
 * @param {*} data  the array to look for a parent in
 * @param {*} child_name  the identifier of the child
 * @param {*} competencies flag that controls which data attribute is compared to the child identifier
 * @returns
 */
export function determineParent(data, child_name, competencies = false) {
	// Recursive call to search what parent has this child
	let node;

	if (competencies && data.length > 0) {
		data?.some(function (n) {
			if (n?.Children?.length && n?.Children.some((e) => getTitleFromTuple(e).Text.toLowerCase() == child_name.toLowerCase())) {
				return (node = getTitleFromTuple(n).Text);
			}

			if (n?.Children?.length) {
				return (node = determineParent(n.Children, child_name, (competencies = true)));
			}
		});
	} else {
		if (data.length < 0) return null;
		data.some(function (n) {
			if (n.children && n.children.some((e) => e.name == child_name)) {
				return (node = n.name);
			}

			if (n.children) {
				return (node = determineParent(n.children, child_name, competencies));
			}
		});
	}

	return node || null; // Parent or Not Found
}

export function flattenTree(data, competencies = false) {
	let flat = [];

	if (competencies) {
		if (Array.isArray(data)) {
			for (const node of data) {
				flat.push(node);

				if (node.Children) {
					flat = flat.concat(flattenTree(node.Children, true));
				}
			}
		} else {
			flat.push(data);
		}
	} else {
		if (Array.isArray(data)) {
			for (const node of data) {
				flat.push(node);

				if (node.Children) {
					flat = flat.concat(flattenTree(node.Children));
				}
			}
		} else {
			flat.push(data);
		}
	}

	return flat;
}

export function hardRefreshPage() {
	window.location.reload(false);
}

export const getInitials = (name) => {
	name = name.trim();

	if (name.length <= 3) return name;

	return name
		.split(/\s+/)
		.map((w) => [...w][0])
		.slice(0, 3)
		.join("");
};

export function readableDate(time) {
	let date = new Date(time);
	let day = date.getDate();
	let month = date.getMonth() + 1;
	const year = date.getFullYear();

	if (month.length < 2) month = "0" + month;
	if (day.length < 2) day = "0" + day;

	date = month + "/" + day + "/" + year;
	return date;
}

export function readableDateWithTime(time) {
	const calendar = readableDate(time);
	const newDate = new Date(time);
	const hour = newDate.getHours();
	let min = newDate.getMinutes();

	if (min < 10) min = "0" + min;

	const clock = `${hour}:${min}`;

	const date = `${calendar} ${clock}`;
	return date;
}

export function findByKey(key, data) {
	const result = data.find((comp) => comp?.Key == key);

	return result;
}

export function randomNonce(length = 10) {
	let nonce = "";
	let chars = "ABCDEFabcdef123456789";

	while (nonce.length < length) {
		let char = chars.charAt(Math.floor(Math.random() * chars.length));
		// no leading digits in ID

		// eslint-disable-next-line eqeqeq
		if (nonce.length == 0) {
			while (!!parseInt(char, 10)) char = chars.charAt(Math.floor(Math.random() * chars.length));
		}
		nonce += char;
	}
	return nonce;
}

export function hasher(name) {
	let hash = Array.from(name)
		.reduce((hash, char) => 0 | (31 * hash + char.charCodeAt(0)), 0)
		.toString();

	return {
		color1: randomNonce(6).substring(0, 3),
		color2: hash.substring(3, 6),
		color3: randomNonce(10).substring(5, 8),
		color4: hash.substring(2, 5),
	};
}

export async function gottaFetchEmAll(url, options = {}) {
	let response, responseBody;

	try {
		response = await fetch(url, options);

		if (response.status == 204) {
			return response;
		}

		responseBody = await response.json();
	} catch (e) {
		let message = response.statusText || "Could not complete request";
		throw new HttpRequestException(message, response.status);
	}

	if (!response?.ok) {
		throw new HttpRequestException(responseBody.errorMessage, responseBody.errorNum);
	}
	return responseBody;
}

export function addToLocalStorage(name, key, value) {
	let existing = localStorage.getItem(name);

	existing = existing ? JSON.parse(existing) : {};

	existing[key] = value;

	localStorage.setItem(name, JSON.stringify(existing));
}

export const findFrameworkId = (Id, data) => data.filter((option) => option.FrameworkId === Id);
export function nextAvailableSortOrder(parent) {
	let maxTso = 0;
	if (!Array.isArray(parent.Children)) return 1;
	for (let child of parent.Children) {
		if (child.TransitiveSortOrder > maxTso) maxTso = child.TransitiveSortOrder;
	}

	return maxTso + 1;
}

export function newTSOs(Children, key, newTSO) {
	let comp = findByKey(key, Children);
	let compsToUpdate = [];

	for (let item of Children) {
		if (item.TransitiveSortOrder >= newTSO) {
			item.TransitiveSortOrder += 1;
			compsToUpdate.push(item);
		}
	}

	comp.TransitiveSortOrder = newTSO;
	compsToUpdate.push(comp);

	compsToUpdate.sort((a, b) => (a.TransitiveSortOrder > b.TransitiveSortOrder ? 1 : -1));

	const uniqueCompsToUpdate = Array.from(new Set(compsToUpdate.map((a) => a.Key))).map((key) => compsToUpdate.find((a) => a.Key === key));

	return uniqueCompsToUpdate;
}

export function decodeHTML(html) {
	const txt = document.createElement("textarea");
	txt.innerHTML = html;
	return txt.value;
}
