import React from "react";
import { NavLink } from "react-router-dom";
import { removeBackdrop } from "../../../_helpers/functions";
import useTheme from "../../../_hooks/useTheme";
import { AppConstants } from "../../../_lib/constants";
import { Drawer } from "../../UI";

function LoginNavBar() {
	const { theme, toggleTheme } = useTheme();
	const rootRoute = AppConstants.publicUrl;
	const activeClassName = "active";

	return (
		<>
			{/* // Nav Toggler */}
			<header className="navbar figma-navbar d-flex align-content-center navbar-full">
				<button
					className="navbar-toggler text-white"
					type="button"
					data-toggle="navdrawer"
					/* data-type="temporary" */
					data-target="#navdrawer_default"
					aria-controls="navdrawer_default"
					aria-expanded="false"
					aria-label="Toggle navigation">
					<span className="navbar-toggler-icon"></span>
				</button>
				<NavLink className="navbar-brand mr-auto text-white" to="/login">
					Arjuna
				</NavLink>
			</header>
			{/* // The Drawer */}
			<Drawer
				nav_id="navdrawer_default"
				header={<span className="navbar-brand justify-content-center d-flex px-0">Arjuna</span>}
				sub_header="Support"
				sub_to_bottom
				sub_children={
					<>
						<li className="nav-item">
							<NavLink className="nav-link text" to="#" onClick={() => toggleTheme()}>
								<i className="material-icons mr-3">{theme == "light" ? "dark_mode" : "light_mode"}</i>{" "}
								{theme === "light" ? "Dark Mode" : "Light Mode"}
							</NavLink>
						</li>
						<li className="nav-item">
							<NavLink className={({ isActive }) => `nav-link text ${isActive && activeClassName}`} to="#">
								<i className="material-icons mr-3">alarm_off</i> Contact
							</NavLink>
						</li>
						<li className="nav-item">
							<NavLink className={({ isActive }) => `nav-link text ${isActive && activeClassName}`} to="#">
								<i className="material-icons mr-3">link</i> Help
							</NavLink>
						</li>
					</>
				}>
				<nav className="navdrawer-nav">
					{/* <NavLink className="nav-link nav-item text" activeClassName="active" exact to="/" onClick={removeBackdrop}>
						Home
					</NavLink> */}
					<NavLink
						className={({ isActive }) => `nav-link text ${isActive && activeClassName}`}
						to={`${rootRoute}/standards`}
						onClick={removeBackdrop}>
						Standards Alignment
					</NavLink>

					<NavLink
						className={({ isActive }) => `nav-link text ${isActive && activeClassName}`}
						to={`${rootRoute}/login`}
						onClick={removeBackdrop}>
						Login
					</NavLink>
				</nav>
			</Drawer>
		</>
	);
}

export default React.memo(LoginNavBar);
