import React, { useContext, useEffect } from "react";
import useTheme from "../../../_hooks/useTheme";
import { LoadingCircle } from "../../MaterialsUI";
import { ErrorContext } from "../../../_lib/contexts";
import StandardsRows from "./StandardsRows";

function StandardsTable({ data, isLoading, error }) {
	const { isLightTheme, isNightTheme } = useTheme();
	const { addError, isError, setIsErrorState } = useContext(ErrorContext);
	const standardsDataChildren = Array.isArray(data) && data[0]?.Children;

	useEffect(() => {
		if (!isLoading && error) {
			setIsErrorState();
			addError("Could not retrieve standards", 500);
		}
	}, [error, isLoading]);

	return isLoading && !isError ? (
		<LoadingCircle />
	) : (
		<div className="document-column-wrapper shadow-1 border-rounded bg-secondary-white">
			<div className="d-flex">
				<p
					className={`w-half d-flex justify-content-center py-3 text-lg font-900 border-right ${
						isLightTheme || isNightTheme ? "border-black" : "border-white"
					} border-w-2 bg-color-light-purple s-text border-top-left-rounded mb-0 h-10`}>
					Standards
				</p>
				<p className="w-half d-flex justify-content-center py-3 text-lg font-900 bg-color-light-purple s-text border-top-right-rounded mb-0 h-10">
					Aligned Competencies
				</p>
			</div>
			<StandardsRows data={standardsDataChildren} loading={isLoading} />
		</div>
	);
}

export default React.memo(StandardsTable);
