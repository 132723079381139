import React from "react";
import { LoginForm } from "../_components/UI";

function LoginLayout() {
	return (
		<div className="login-background container-fluid d-flex h-screen w-screen pt-5">
			<div className="login-welcome d-flex w-half justify-content-end align-items-start pr-7 pt-5">
				<div className="d-flex flex-column">
					<p className="font-600 text-6xl">Welcome</p>
					<p className="font-600 -mt-2 text-6xl">Back!</p>
				</div>
			</div>
			<div className="w-half d-flex justify-content-center align-items-center">
				<div className="login-form-container d-flex flex-column bg-white px-5">
					<h2 className="d-flex justify-content-center my-2 w-full">Log In</h2>
					<p className="text-lg">Sign in for access to internal authoring tools.</p>
					<LoginForm />
				</div>
			</div>
		</div>
	);
}

export default React.memo(LoginLayout);
