import React from "react";
import useTheme from "../../../_hooks/useTheme";

function PropertiesBox({ children, label, centered, width_class, className }) {
	const { isNightTheme } = useTheme();

	return (
		<div className={`border-1 mx-1 cursor-default ${width_class} ${isNightTheme ? "bg-color-dark" : "bg-white"}`}>
			<div className="border-bottom-1 d-flex justify-content-center font-600 bg-color-light-purple py-1 px-1">{label}</div>
			<div
				className={`d-flex py-1 px-2 ${children && children?.length > 4 && "flex-column"} ${
					centered ? "justify-content-center" : ""
				} ${className}`}>
				{children ? children : "N/A"}
			</div>
		</div>
	);
}

export default PropertiesBox;
