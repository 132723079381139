import React, { useContext } from "react";
import { ErrorContext } from "../../../_lib/contexts";
import { Modal } from "../../MaterialsUI";

function ErrorModal({ error, on_action_callback }) {
	const { error: err, removeError, isError } = useContext(ErrorContext);

	// active error state
	var activeError = error || err;

	//respond to dispatched error state
	return (
		<Modal
			id="error_modal"
			show={isError}
			// setShow={setShow}
			dispatch={removeError}
			title={`Status: ${activeError?.statusCode}`}
			action_text={"Okay"}
			center_title={true}
			min_width={400}
			content={true}
			on_action_callback={on_action_callback || removeError}
			name="error">
			{`Message: ${activeError?.message}`}
		</Modal>
	);
}

export default ErrorModal;
