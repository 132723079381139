import React, { useState, createContext } from "react";

const InitialCompetency = {
	Key: "0c2625519e04a468b4a553c2b55907e4",
	Revision: "_dPk5leO--_",
	Title: [
		{
			Language: "en-US",
			Text: "Numbers and Operations: Fractions",
			Id: "c2015279-f38d-45d8-b84d-47d8fc67ec93",
		},
	],
	AlternateLabel: [],
	Creator: "Big Ideas Learning",
	Notes: [],
	Tags: [],
	Token: "numbers-and-operations-fractions",
	Definition: [
		{
			Language: "en-US",
			Text: "The domain of fractions and operations involoving fractions.",
			Id: "295f3992-55fa-4c4a-b92d-57fea16ad202",
		},
	],
	Scopes: [],
	LastModified: "2021-09-24T19:52:27.8",
	Children: [],
	Relationships: [],
	num: 1,
};

const CompetencyRootContext = createContext([{}, () => {}]);

const CompetencyRootProvider = ({ children }) => {
	// Hard Coded Iitaial State for Default
	const [state, setState] = useState(InitialCompetency);

	return <CompetencyRootContext.Provider value={[state, setState]}>{children}</CompetencyRootContext.Provider>;
};

export { CompetencyRootContext, CompetencyRootProvider };
