import { components } from "react-select";
import React from "react";

export function SelectTheme(isNightTheme, menuHeight) {
	const selectFocus = {
		control: (base, state) => ({
			...base,
			backgroundColor: "#6200ee",
			borderRadius: "80px",
			boxShadow: state.isFocused ? 0 : "0 1px 1px 0 rgb(0 0 0 / 14%), 0 1px 3px 0 rgb(0 0 0 / 12%), 0 2px 1px -1px rgb(0 0 0 / 20%)",
			"&:hover": {
				border: state.isFocused ? 0 : 0,
				cursor: "pointer",
			},
			margin: 0,
			padding: "0 1rem",
			minWidth: "10rem",
			position: "relative",
			outline: "none",
			border: state.isFocused ? 0 : 0,
			height: "2.25rem",
			minHeight: 0,
		}),
		placeholder: (base) => ({
			...base,
			color: "#FFFFFF",
		}),
		singleValue: (base) => ({
			...base,
			color: "#FFFFFF",
			overflow: "hidden",
			whiteSpace: "nowrap",
			textOverflow: "ellipsis",
			minWidth: "10.75rem",
			maxWidth: "12rem",
			letterSpacing: "0.0892857143em",
		}),
		menuList: (base) => ({
			...base,
			color: isNightTheme ? "#c7c7c7" : "#1c1f25",
			backgroundColor: isNightTheme ? "#34383d" : "#FFFFFF",
			height: menuHeight,
		}),
		option: (base, state) => ({
			...base,
			backgroundColor: state.isSelected ? "#6200ee" : "rgba(0, 0, 255, 0)",
		}),
		indicatorSeparator: (base) => ({
			...base,
			display: "none",
		}),
	};

	return selectFocus;
}

export const DropdownIndicator = (props) => {
	return (
		<components.DropdownIndicator {...props}>
			<i className="material-icons text-white">arrow_drop_down</i>
		</components.DropdownIndicator>
	);
};
