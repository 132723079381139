import React, { useContext, useEffect } from "react";
import { useInView } from "react-intersection-observer";
import { useCompetencies } from "../../../_hooks/useCompetencies";
import { CompetenciesToAlignContext } from "../../../_lib/contexts";
import CompetencyTree from "../../Competencies/CompetencyTree";
import { Button } from "../../MaterialsUI";

function CompetencyAlignmentForm({ setShowDiscard, handleAlignment, handleResetOrEdit, standardVersion, edit = false }) {
	const { alignedCompetencies } = useContext(CompetenciesToAlignContext);
	const { data, error, isFetchingNextPage, isFetchingPreviousPage, fetchNextPage, fetchPreviousPage, hasNextPage, hasPreviousPage } =
		useCompetencies();
	const { ref, inView } = useInView();
	const { ref: upperRef, inView: upperInView } = useInView();

	useEffect(() => {
		if (inView && hasNextPage) {
			fetchNextPage();
		}
	}, [fetchNextPage, inView]);
	useEffect(() => {
		if (upperInView && hasPreviousPage) {
			fetchPreviousPage();
		}
	}, [fetchPreviousPage, upperInView]);

	return (
		<>
			<div className="my-4 text text-lgish overflow-auto w-full">
				<CompetencyTree
					data={data?.pages}
					depth={0}
					selecting={false}
					checker={true}
					drag={false}
					edit={edit}
					error={error}
					modal={true}
					isError={!!error}
					innerRef={ref}
					upperRef={upperRef}
					hasNextPage={hasNextPage}
					loadingNextPage={isFetchingNextPage}
					hasPrevPage={hasPreviousPage}
					loadingPrevPage={isFetchingPreviousPage}
				/>
			</div>
			<div className="d-flex justify-content-center">
				<Button
					className="rounded-input btn-white bg-white mr-4"
					onClick={() => {
						edit ? handleResetOrEdit(alignedCompetencies.editedCompetencies, standardVersion) : handleAlignment();
					}}
					disabled={
						edit
							? !alignedCompetencies?.addedCompetencies?.length > 0 && !alignedCompetencies?.removedCompetencies?.length > 0
							: !alignedCompetencies?.addedCompetencies?.length > 0
					}
					data_testid="save_btn">
					Save <i className="material-icons ml-2">check_circle</i>
				</Button>
				<Button
					className="rounded-input btn-white bg-white ml-4"
					data_testid="exit_btn"
					onClick={() => {
						setShowDiscard(true);
					}}>
					Cancel <i className="material-icons ml-2">highlight_off</i>
				</Button>
			</div>
		</>
	);
}

export default React.memo(CompetencyAlignmentForm);
