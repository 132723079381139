import React from "react";

function MoreVert() {
	return (
		<div className="dropdown inline-dropdown" style={{ float: "right" }}>
			<i
				className="material-icons vertical-toggle"
				aria-expanded="false"
				aria-haspopup="true"
				data-toggle="dropdown"
				id="dropdown_menu_button_1">
				more_vert
			</i>

			<div aria-labelledby="dropdown_menu_button_1" className="dropdown-menu dropdown-menu-sm menu">
				<a className="dropdown-item" href="#">
					Duplicate
				</a>
				<a className="dropdown-item" href="#">
					Edit
				</a>
				<a className="dropdown-item" href="#">
					Deprecate
				</a>
			</div>
		</div>
	);
}

export default React.memo(MoreVert);
