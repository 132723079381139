export const loginInitialState = {
	email: { value: "", touched: false, hasError: true, error: "" },
	password: { value: "", touched: false, hasError: true, error: "" },
	isFormValid: false,
};

export const competencyFormInitialState = {
	Title: { value: "", touched: false, hasError: false, error: "" },
	Definition: { value: "", touched: false, hasError: false, error: "" },
	Parent: { value: "", touched: false, hasError: false, error: "" },
	Tags: { value: "", touched: false, hasError: false, error: "" },
	Note: { value: "", touched: false, hasError: false, error: "" },
	isFormValid: false,
};

export const alignmentDocumentFormInitialState = {
	Title: { value: "", touched: false, hasError: false, error: "" },
	Description: { value: "", touched: false, hasError: false, error: "" },
	BaseStateStandard: { value: "", touched: false, hasError: false, error: "" },
	Level: { value: "", touched: false, hasError: false, error: "" },
	isFormValid: false,
};

export function formReducer(state, action) {
	switch (action.type) {
		case "UPDATE_FORM":
			// eslint-disable-next-line no-case-declarations
			const { name, value, hasError, error, touched, isFormValid } = action.data;
			return {
				...state,
				[name]: { ...state[name], value, hasError, error, touched },
				isFormValid,
			};

		case "SET_FORM":
			if (!action.data?.Title) return;
			return {
				Title: { value: action.data.Title.value, touched: true, hasError: false, error: "" },
				Definition: { value: action.data.Definition.value, touched: true, hasError: false, error: "" },
				Parent: { value: action.data.Parent.value, touched: true, hasError: false, error: "" },
				Tags: { value: action.data.Tags.value, touched: true, hasError: false, error: "" },
				Note: { value: "", touched: true, hasError: false, error: "" },
				isFormValid: false,
			};

		case "SET_CLONE_FORM":
			if (!action.data?.Title) return;
			return {
				Title: { value: `SIBLING OF ${action.data.Title.value}`, touched: true, hasError: false, error: "" },
				Definition: { value: `SIBLING OF ${action.data.Definition.value}`, touched: true, hasError: false, error: "" },
				Parent: { value: action.data.Parent.value, touched: true, hasError: false, error: "" },
				Tags: { value: action.data.Tags.value, touched: true, hasError: false, error: "" },
				Note: { value: "", touched: true, hasError: false, error: "" },
				isFormValid: true,
			};

		default:
			return state;
	}
}
