import React, { useContext, useState } from "react";
import { CompetencyContext } from "../../../_lib/contexts/CompetencyContext";
import { Button } from "../../MaterialsUI";
import CompetencyPropertiesCard from "./CompetencyPropertiesCard";
import Modal from "../../MaterialsUI/Modal";
import ConfirmOrCancelModal from "../../UI/Modals/ConfrimOrCancelModal";
import { CompetencyRootContext } from "../../../_lib/contexts/CompetencyRootContext";
import ModalContent from "../../MaterialsUI/ModalContent";
import { useDeprecateCompetency } from "../../../_hooks/useCompetencies";
import { CloneContext, ErrorContext, SaveContext } from "../../../_lib/contexts";
import { getTitleFromTuple } from "../../../_helpers/functions";
import useAuth from "../../../_hooks/useAuth";
import CloneForm from "../Forms/CloneForm";
import EditForm from "../Forms/EditForm";

function CompetencyProperties({ data }) {
	const { competency } = useContext(CompetencyContext);
	const [competencyRootState] = useContext(CompetencyRootContext);
	const { addError, isError: errorHappening, setIsErrorState } = useContext(ErrorContext);
	const { isSaved, isLoading, addLoadingMessage, removeSaveModal } = useContext(SaveContext);
	const { addCloneData, setIsClone, setShowCloneModal, showCloneModal } = useContext(CloneContext);
	const { mutateAsync: deprecate } = useDeprecateCompetency();
	const { currentUser } = useAuth();

	const [show, setShow] = useState(false);
	const [showEditor, setShowEditor] = useState(false);
	const [showNoDeprecate, setShowNoDeprecate] = useState(false);

	const relativeLength = competency?.Children.length;
	const noData = !data?.length;
	const isRoot = competency === competencyRootState;

	async function handleDeprecate() {
		setShow(!show);
		isLoading();
		addLoadingMessage(`Deprecating ${getTitleFromTuple(competency)?.Text}...`);
		const competency_key = competency.Key;
		const revision = competency?.Revision ?? competency._rev;
		const user = currentUser.name;
		const variables = {
			competency_key,
			revision,
			user,
		};

		try {
			await deprecate(variables);
		} catch (e) {
			setIsErrorState();
			addError(e.message, e.statusCode);
			removeSaveModal();
		}

		if (!errorHappening) setTimeout(() => isSaved(), 1500);
	}

	return (
		<>
			<div className="competencies-editor d-flex shadow-1 justify-content-between mt-3 flex-row p-3">
				<div className="scope-view-content">
					<CompetencyPropertiesCard data={competency} apiData={data} />
				</div>
				<div className="d-flex flex-column ml-3">
					<Button
						className="rounded-input bg-secondary-white disabled my-3 border-none"
						disabled={true}
						data_testid="publish_btn">
						Publish
					</Button>

					<Button
						data_testid="edit_btn"
						disabled={noData || isRoot}
						className={`rounded-input bg-secondary-white mb-3 ${noData ? "disabled" : "text"}`}
						onClick={() => setShowEditor(!showEditor)}>
						Edit
					</Button>

					<Button
						data_testid="clone_draft_btn"
						disabled={noData || isRoot}
						className={`rounded-input bg-secondary-white mb-3 border-none ${noData ? "disabled" : "text"}`}
						onClick={() => {
							addCloneData(competency);
							setIsClone();
							setShowCloneModal();
						}}>
						Clone & Create Sibling
					</Button>

					<Button
						data_testid="deprecate_btn"
						className={`rounded-input bg-secondary-white mb-3 border-none ${isRoot ? "disabled" : "text"}`}
						onClick={() => (relativeLength > 0 ? setShowNoDeprecate(!showNoDeprecate) : setShow(!show))}
						disabled={noData || isRoot}>
						Deprecate
					</Button>
				</div>
			</div>

			<Modal show={show} setShow={() => setShow(false)} content>
				<ConfirmOrCancelModal label={"Deprecate"} onClickConfirm={() => handleDeprecate()} onClickCancel={() => setShow(!show)}>
					Confirm that you want to deprecate the competency{" "}
					<b className="text-capitalize">{getTitleFromTuple(competency, "Title")?.Text}</b>. Doing so will{" "}
					<b>unlink it from all</b> competencies, learning objects, and other alignments.
				</ConfirmOrCancelModal>
			</Modal>
			<Modal show={showNoDeprecate}>
				<ModalContent
					title={"Cannot Deprecate!"}
					setShow={() => setShowNoDeprecate(false)}
					center_title={true}
					closing_text={"Okay"}>
					A competency with children cannot be deleted. Please connect the children of this competency to other parents before
					deleting it.
				</ModalContent>
			</Modal>
			<Modal show={showEditor}>
				<EditForm show={showEditor} setShow={setShowEditor} />
			</Modal>
			<Modal show={showCloneModal}>
				<CloneForm />
			</Modal>
		</>
	);
}

export default React.memo(CompetencyProperties);
