import { useContext } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { findByKey, flattenTree } from "../_helpers/functions";
import { apiBase } from "../_lib/config";
import { NewlyTouchedContext } from "../_lib/contexts/NewlyTouchedContext";
import { useFetchWrapper } from "./useFetchWrapper";

// React Query Hooks
export function useTags() {
	const fetchWrapper = useFetchWrapper();

	const getTags = async () => {
		const url = `${apiBase}/tags`;
		return await fetchWrapper.get(url);
	};

	return useQuery({ queryKey: ["tags"], queryFn: getTags });
}

export function useAddTags() {
	// eslint-disable-next-line no-unused-vars
	const [_competencyState, setNewlyTouched] = useContext(NewlyTouchedContext);
	const queryClient = useQueryClient();
	const fetchWrapper = useFetchWrapper();

	const addTags = async (options) => {
		const url = `${apiBase}/competencies/${options.key}/${options.revision}/tags`;
		return {
			result: await fetchWrapper.post(url, options.body),
			options,
		};
	};

	return useMutation({
		mutationFn: addTags,
		onSettled: (data) => {
			queryClient.invalidateQueries({ queryKey: ["competencies"] });
			let comps = queryClient.getQueryData(["competencies"]);
			const newTags = JSON.parse(data.options.body.body);

			comps = flattenTree(comps, true);

			let newlyEdited = findByKey(data.options.key, comps);
			let combinedTags = newlyEdited.Tags.concat(newTags);

			newlyEdited.Tags = combinedTags;
			setNewlyTouched(newlyEdited);
		},
	});
}

export function usePatchTags() {
	// eslint-disable-next-line no-unused-vars
	const [_competencyState, setNewlyTouched] = useContext(NewlyTouchedContext);
	const queryClient = useQueryClient();
	const fetchWrapper = useFetchWrapper();

	const patchTags = async (options) => {
		const url = `${apiBase}/competencies/${options.key}/${options.revision}/tags`;
		return {
			result: await fetchWrapper.patch(url, options.body),
			options,
		};
	};

	return useMutation({
		mutationFn: patchTags,
		onSettled: (data) => {
			queryClient.invalidateQueries({ queryKey: ["competencies"] });
			let comps = queryClient.getQueryData(["competencies"]);
			const newTags = JSON.parse(data.options.body.body);

			comps = flattenTree(comps, true);

			let newlyEdited = findByKey(data.options.key, comps);
			newlyEdited.Tags = newTags;
			setNewlyTouched(newlyEdited);
		},
	});
}
