import React from "react";
import useTheme from "../../_hooks/useTheme";
import NewAlignmentDocumentForm from "./forms/NewAlignmentDocumentForm";

function NewAlignmentDocumentModal({ show, setShow }) {
	const { isSunsetTheme } = useTheme();
	return (
		<div
			className={`editor d-flex justify-content-start shadow-1 p-4 ${isSunsetTheme ? "bg-light-themed" : "background"}`}
			onClick={(e) => e.stopPropagation()}>
			<NewAlignmentDocumentForm show={show} setShow={setShow} />
		</div>
	);
}

export default NewAlignmentDocumentModal;
