import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "../_components/MaterialsUI";

function NotFoundLayout() {
	const navigate = useNavigate();

	const routeBack = useCallback(() => {
		navigate(-1);
	}, []);

	return (
		<section className="d-flex flex-column justify-content-center align-items-center h-screen">
			<img src="./descartes.svg" alt="Descartes the Cat" height={400} width={400} />
			<div className="h2 my-5">Sorry, this page doesn&apos;t exist!</div>
			<Button onClick={routeBack} className="rounded-input bg-color-dark text-white">
				Return to Previous Page
			</Button>
		</section>
	);
}

export default React.memo(NotFoundLayout);
