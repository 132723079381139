import React, { useContext, useEffect, useReducer, useState } from "react";
import { decodeHTML, getAncestors } from "../../../_helpers/functions";
import { useGetAlignedCompetencies } from "../../../_hooks/useStandardsAlignment";
import useTheme from "../../../_hooks/useTheme";
import { CompetenciesToAlignProvider, StandardsAlignmentDocumentContext } from "../../../_lib/contexts";
import { graphTreeInitialState as TreeInitialState, treeReducer } from "../../../_reducers/treeReducer";
import { LoadingCircle, Button, Modal } from "../../MaterialsUI";
import CompetencyAlignmentModal from "../CompetencyAlignmentModal";
import AlignedCompetenciesSection from "./AlignedCompetenciesSection";
import DOMPurify from "dompurify";

function StandardsRows({ data, loading, d, className }) {
	const [state, dispatch] = useReducer(treeReducer, TreeInitialState);
	const [currentStandardState, setCurrentStandardState] = useState();
	const [showCompetencyAlignmentModal, setShowCompetencyAlignmentModal] = useState(false);
	const [currentDocument] = useContext(StandardsAlignmentDocumentContext);
	const { isNightTheme } = useTheme();
	const { data: standardsData, isLoading } = useGetAlignedCompetencies(currentDocument?.Key) || {};

	const currentStandardId = currentStandardState?.Id;
	const maxAlignmentIndex = currentDocument?.MaxAlignmentIndex;

	function handleOpen(name) {
		dispatch({
			type: "OPEN",
			name,
		});
	}

	function handleClose(item) {
		dispatch({
			type: "CLOSE",
			item,
		});
	}

	function handleClick(item, id) {
		const newState = { ...item, id };
		setCurrentStandardState(newState);
	}

	useEffect(() => {
		if (currentStandardState) {
			const parents = getAncestors(data, currentStandardId, 40, false);

			if (parents?.length) parents.forEach((c) => handleOpen(c.Id));

			handleOpen(currentStandardId);
		}
	}, [currentStandardState]);

	if (loading || isLoading) {
		return (
			<div className="d-flex justify-content-center align-content-center h-full w-full">
				<LoadingCircle loading={loading} />
			</div>
		);
	}

	return (
		<>
			{Array.isArray(data) && data.length > 0 ? (
				data.map((item, idx) => {
					const children = item?.Children,
						title = decodeHTML(DOMPurify.sanitize(item.Description)),
						path = item.Path,
						depth = item.Depth,
						id = item.Id,
						// value = item.value,
						version = item.Version,
						isAligned = standardsData?.some((s) => s?.StandardId == id),
						alignmentData = standardsData?.filter((s) => s.StandardId == id),
						hasCompetencies = alignmentData && alignmentData[0]?.CompetencyKeys.length > 0,
						alignedVersion = alignmentData && alignmentData[0]?.StandardVersion;

					// parentId = item.parentId;

					return (
						<div key={idx} id={`${idx}_${depth}`}>
							<div
								className={`${className ?? ""} item ${children?.length > 0 ? "font-900" : "font-500"}  border-top-1 h-full`}
								data-depth={depth == 1 ? 0 : depth}
								data-testid={`${idx}_${d}`}>
								<div className="d-flex">
									<div
										className={`d-flex align-items-center py-3 pr-2 w-half border-right h-full position-relative ${
											isNightTheme ? "border-white" : "border-black"
										}`}>
										{!state.currentItem.includes(id) ? (
											<button
												data-testid="open"
												className={`btn-outline-* pointer border-0 text ${!children?.length && "cursor-default"}`}
												onClick={() => handleOpen(id)}>
												<div className="block">
													<i className={`material-icons ${!children?.length && "invisible"}`}>add</i>
												</div>
											</button>
										) : (
											<button
												data-testid="close"
												className={`btn-outline-* pointer border-0 text ${!children?.length && "cursor-default"}`}
												onClick={() => handleClose(id)}>
												<div className="block">
													<i className={`material-icons ${!children?.length && "invisible"}`}>remove</i>
												</div>
											</button>
										)}
										<div
											className={`${
												children?.length > 0 ? "font-bold font-800 tracing-half" : "font-400"
											} cursor-default text-capitalize text d-flex`}>
											<p className="pr-3 m-0 tracing-1 text-base">{path}</p>
											{/* Need to cleanse the html for security */}
											<p className="m-0 text-base pr-2" dangerouslySetInnerHTML={{ __html: title }} />
										</div>
										<div
											className={`border-rounded ${
												isAligned && hasCompetencies && alignedVersion != version ? "bg-red" : "bg-muted"
											} px-1 font-400 text-sm cursor-default position-absolute top-1 right-2`}>
											v{version}
										</div>
									</div>
									<div
										className={`d-flex align-items-center justify-content-center pr-2 w-half position-relative border-left ${
											isNightTheme ? "border-white" : "border-black"
										}`}>
										{isAligned && hasCompetencies ? (
											<AlignedCompetenciesSection
												isNightTheme={isNightTheme}
												alignmentData={alignmentData}
												version={version}
												handleClick={() => handleClick(item, id)}
												currentStandardState={currentStandardState}
											/>
										) : maxAlignmentIndex <= depth ? (
											<Button
												className={`pointer text btn rounded-input border ${
													isNightTheme ? "border-white" : "border-black"
												} border-w-1 text-capitalize shadow-none text-shadow-1`}
												onClick={() => {
													setShowCompetencyAlignmentModal(true);
													handleClick(item, id);
												}}>
												Align
											</Button>
										) : null}
									</div>
								</div>
							</div>
							{state.currentItem.includes(id) && children?.length > 0 && (
								<StandardsRows id={title} data={children} depth={depth + 1} />
							)}
						</div>
					);
				})
			) : (
				<div className="list-item" data-depth={data.Depth}>
					{data.length > 0 && (
						<div className={``}>
							{data.Description}
							{data.Children.length > 0 && <StandardsRows data={data.Children} depth={d + 1} />}
						</div>
					)}
				</div>
			)}
			<Modal show={showCompetencyAlignmentModal}>
				<CompetenciesToAlignProvider>
					<CompetencyAlignmentModal
						show={showCompetencyAlignmentModal}
						setShow={setShowCompetencyAlignmentModal}
						currentStandard={currentStandardState}
					/>
				</CompetenciesToAlignProvider>
			</Modal>
		</>
	);
}

export default React.memo(StandardsRows);
