import React, { createContext, useCallback, useState } from "react";
import { getDefinitionFromTuple, getTitleFromTuple } from "../../_helpers/functions";

const CloneContext = createContext({
	cloneData: null,
	isCloneState: null,
	showCloneModal: null,
	setIsClone: () => {},
	addCloneData: () => {},
	resetCloneState: () => {},
	setShowCloneModal: () => {},
	setHideCloneModal: () => {},
});

const CompetencyCloneProvider = ({ children }) => {
	const [cloneData, setCloneData] = useState(null);
	const [isCloneState, setIsCloneState] = useState(false);
	const [showCloneModal, setShowCloneModalState] = useState(false);

	const resetCloneState = () => {
		setCloneData(null);
		setIsCloneState(false);
	};
	const addCloneData = (data) => {
		data = {
			Title: {
				value: getTitleFromTuple(data).Text,
			},
			Definition: {
				value: getDefinitionFromTuple(data).Text,
			},
			Tags: {
				value: data.Tags,
			},
		};
		setCloneData(data);
	};
	const setIsClone = () => setIsCloneState(true);
	const setShowCloneModal = () => setShowCloneModalState(true);
	const setHideCloneModal = () => setShowCloneModalState(false);

	const contextValue = {
		cloneData,
		isCloneState,
		showCloneModal,
		addCloneData: useCallback((data) => addCloneData(data), []),
		resetCloneState: useCallback(() => resetCloneState(), []),
		setIsClone: useCallback(() => setIsClone(), []),
		setShowCloneModal: useCallback(() => setShowCloneModal(), []),
		setHideCloneModal: useCallback(() => setHideCloneModal(), []),
	};

	return <CloneContext.Provider value={contextValue}>{children}</CloneContext.Provider>;
};

export { CloneContext, CompetencyCloneProvider };
