import React from "react";
import { Link } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { removeBackdrop } from "../../../_helpers/functions";
import useAuth from "../../../_hooks/useAuth";
import { AppConstants } from "../../../_lib/constants";

function DrawerContent({ tab = false }) {
	const rootRoute = AppConstants.publicUrl;
	const { logout } = useAuth();
	const activeClassName = "active";

	function logUserOut() {
		removeBackdrop();
		logout();
	}

	return tab ? (
		<>
			<div className="h-full">
				<ul className="nav nav-tabs" id="justifiedTab" role="tablist">
					<li className="nav-item">
						<NavLink
							className={({ isActive }) => `nav-link text ${isActive && activeClassName}`}
							to={`${rootRoute}/standards`}
							role="tab">
							Standards Alignment
						</NavLink>
					</li>
					<li className="nav-item">
						<NavLink
							className={({ isActive }) => `nav-link text ${isActive && activeClassName}`}
							to={`${rootRoute}/`}
							role="tab">
							Graph Authoring
						</NavLink>
					</li>
				</ul>
			</div>
		</>
	) : (
		<nav className="navdrawer-nav">
			<NavLink
				className={({ isActive }) => `nav-link nav-item text ${isActive && activeClassName}`}
				to={`${rootRoute}/`}
				onClick={removeBackdrop}>
				Graph Authoring
			</NavLink>
			<NavLink
				className={({ isActive }) => `nav-link nav-item text ${isActive && activeClassName}`}
				to={`${rootRoute}/standards`}
				onClick={removeBackdrop}>
				Standards Alignment
			</NavLink>
			<Link className="nav-link nav-item text" onClick={logUserOut} to={`${rootRoute}/login`}>
				Logout
			</Link>
		</nav>
	);
}

export default DrawerContent;
