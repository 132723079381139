import { useContext } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { findByKey, flattenTree } from "../_helpers/functions";
import { apiBase, remoteBase } from "../_lib/config";
import { StandardsAlignmentDocumentContext } from "../_lib/contexts/StandardsDocumentContext";
import { useFetchWrapper } from "./useFetchWrapper";

// ReactQuery Hooks
export function useStandardFrameworks() {
	const fetchWrapper = useFetchWrapper();

	const getStandardFrameworks = async () => {
		// eslint-disable-next-line no-undef
		const url = `${remoteBase}/standards/frameworks`;
		return await fetchWrapper.get(url);
	};

	return useQuery({ queryKey: ["standard_frameworks"], queryFn: getStandardFrameworks });
}

export function useStandardsAlignments() {
	const fetchWrapper = useFetchWrapper();

	const getStandardsAlignments = async () => {
		const url = `${apiBase}/standards/alignments`;
		return await fetchWrapper.get(url);
	};

	return useQuery({ queryKey: ["standards_alignments"], queryFn: getStandardsAlignments, retry: false });
}

export function useStandardsAlignmentById({ Id }) {
	const fetchWrapper = useFetchWrapper();

	const getStandardsAlignmentById = async (id) => {
		const url = `${apiBase}/standards/alignments/${id}`;
		return await fetchWrapper.get(url);
	};

	return useQuery({ queryKey: ["standards_id", Id], queryFn: getStandardsAlignmentById });
}

export function useGetStandards(id) {
	const fetchWrapper = useFetchWrapper();

	const getStandards = async (framework_id) => {
		const url = `${remoteBase}/standards/list/${framework_id}?structure=tree`;
		return await fetchWrapper.get(url);
	};

	if (!id) return;
	return useQuery({ queryKey: ["standards", id], queryFn: () => getStandards(id), retry: false });
}

export function useGetAlignedCompetencies(map_key) {
	const fetchWrapper = useFetchWrapper();
	const getCompetencyToStandardAlignment = async (map_key) => {
		const url = `${apiBase}/standards/alignments/compDescriptions/${map_key}`;
		return await fetchWrapper.get(url);
	};
	if (!map_key) return;
	return useQuery({ queryKey: ["competency_alignments", map_key], queryFn: () => getCompetencyToStandardAlignment(map_key) });
}

export function useAddStandardsAlignment() {
	const queryClient = useQueryClient();
	// eslint-disable-next-line no-unused-vars
	const [_currentDocument, setCurrentDocument] = useContext(StandardsAlignmentDocumentContext);
	const fetchWrapper = useFetchWrapper();

	const addStandardsAlignment = async (options) => {
		const url = `${apiBase}/standards/alignments`;
		return await fetchWrapper.post(url, options.body, options.headers);
	};

	return useMutation({
		mutationFn: addStandardsAlignment,
		onSuccess: async (data) => {
			await queryClient.invalidateQueries({ queryKey: ["standards_alignments"] });
			let docsData = queryClient.getQueryData(["standards_alignments"]);
			let docs = flattenTree(docsData, true);
			let newDoc = findByKey(data.Key, docs);
			setCurrentDocument(newDoc);
		},
	});
}

export function useAlignCompetenciesToStandard() {
	const queryClient = useQueryClient();
	const fetchWrapper = useFetchWrapper();

	const alignCompetencies = async (options) => {
		const url = `${apiBase}/standards/alignments/${options.map_key}/${options.standard_uuid}`;
		return await fetchWrapper.put(url, options.body, options.headers);
	};

	return useMutation({
		mutationFn: alignCompetencies,
		onSuccess: async () => {
			await queryClient.invalidateQueries({ queryKey: ["competency_alignments"] });
		},
	});
}

export function useUpdateCompetencyAlignment() {
	const queryClient = useQueryClient();
	const fetchWrapper = useFetchWrapper();

	const updateAlignedCompetencies = async (options) => {
		const url = `${apiBase}/standards/alignments/${options.map_key}/${options.standard_uuid}/${options.standard_rev}${
			options.update ? "?updateEntity=1" : ""
		}`;
		return await fetchWrapper.put(url, options.body, options.headers);
	};

	return useMutation({
		mutationFn: updateAlignedCompetencies,
		onSuccess: async () => {
			await queryClient.invalidateQueries({ queryKey: ["competency_alignments"] });
		},
	});
}
