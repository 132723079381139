import React from "react";

function LearningPathSelect({ data, className }) {
	return (
		<>
			{data.map((item, idx) => {
				return (
					<React.Fragment key={idx}>
						<option data-testid={item.name} className={`${className} font-weight-bold`} value={JSON.stringify(item)}>
							{item.name}
						</option>
						{item.children && <LearningPathSelect data={item.children} className="font-400" />}
					</React.Fragment>
				);
			})}
		</>
	);
}

export default LearningPathSelect;
