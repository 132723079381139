import React, { useState, createContext } from "react";

const InitialState = null;

const StandardsAlignmentDocumentContext = createContext([{}, () => {}]);

const StandardsAlignmentDocumentProvider = ({ children }) => {
	const [state, setState] = useState(InitialState);

	return <StandardsAlignmentDocumentContext.Provider value={[state, setState]}>{children}</StandardsAlignmentDocumentContext.Provider>;
};

export { StandardsAlignmentDocumentContext, StandardsAlignmentDocumentProvider };
