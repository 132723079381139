import React, { useContext, useState } from "react";
import { CompetencyContext } from "../../_lib/contexts/CompetencyContext";
import { LearningPathContext } from "../../_lib/contexts/LearningPathContext";
import SearchBar from "../MaterialsUI/SearchBar";
import { CompetencyTree } from "../Competencies";
import { ContentItem } from "../LearningPath";
import { Modal } from "../MaterialsUI";
import { getTitleFromTuple } from "../../_helpers/functions";

function Tree({
	path,
	group,
	competencies = false,
	loading,
	error,
	isError,
	innerRef,
	upperRef,
	hasNextPage,
	hasPrevPage,
	loadingNextPage,
	loadingPrevPage,
}) {
	const [state] = useContext(LearningPathContext);
	const [show, setShow] = useState(false);
	const { competency } = useContext(CompetencyContext);

	return (
		<>
			<div className="tree-header shadow-1 px-3">
				<div className="d-flex justify-content-between align-items-end flex-row">
					<div className="h5 text cursor-default">{group}</div>
					<SearchBar data={path} placeholder="Search Tree..." input_className="input-w-no-bg" />
				</div>
				<button
					data-testid="dropdown-btn"
					onClick={() => setShow(!show)}
					className="d-flex justify-content-between align-items-center btn rounded-input text-capitalize border-1 h-25 text text-nowrap w-full overflow-hidden shadow-none">
					<span className="overflow-ellipsis">
						{competencies ? `${competency?.PeopleId || ""} ${getTitleFromTuple(competency)?.Text}` : state.name}
					</span>
					<i className="material-icons">arrow_drop_down</i>
				</button>
			</div>
			<div className="tree-card auto-layout shadow-1 text overflow-x-none">
				{competencies ? (
					<CompetencyTree
						data={path}
						depth={0}
						loading={loading}
						error={error}
						isError={isError}
						innerRef={innerRef}
						upperRef={upperRef}
						hasNextPage={hasNextPage}
						loadingNextPage={loadingNextPage}
						hasPrevPage={hasPrevPage}
						loadingPrevPage={loadingPrevPage}
					/>
				) : (
					<ContentItem depth={0} data={path} />
				)}
			</div>
			<Modal show={show} setShow={() => setShow(false)} search_bar exit_button content min_width>
				<div className="text">
					{competencies ? (
						<>
							<p className="text text-lg font-bold">{group}</p>
							<CompetencyTree
								data={path}
								depth={0}
								select={true}
								drag={false}
								loading={loading}
								error={error}
								modal={true}
								isError={isError}
								innerRef={innerRef}
								upperRef={upperRef}
								hasNextPage={hasNextPage}
								loadingNextPage={loadingNextPage}
								hasPrevPage={hasPrevPage}
								loadingPrevPage={loadingPrevPage}
							/>{" "}
						</>
					) : (
						<ContentItem depth={0} data={path} />
					)}
				</div>
			</Modal>
		</>
	);
}

export default React.memo(Tree);
