import React, { useState, createContext, useCallback } from "react";

// Convert to reducer instead of useState

const SaveContext = createContext({
	saved: null,
	loading: null,
	loadingMessage: null,
	isSaved: () => {},
	isLoading: () => {},
	addLoadingMessage: () => {},
	removeSaveModal: () => {},
});

const SaveProvider = ({ children }) => {
	const [saved, setSaved] = useState(false);
	const [loading, setLoading] = useState(false);
	const [loadingMessage, setLoadingMessage] = useState(null);

	const removeSaveModal = () => {
		setSaved(false);
		setLoading(false);
		setLoadingMessage(null);
	};

	const isSaved = () => setSaved(true);

	const isLoading = () => setLoading(true);
	const addLoadingMessage = (message) => setLoadingMessage({ message });

	const contextValue = {
		saved,
		loading,
		loadingMessage,
		isSaved: useCallback(() => isSaved(), []),
		isLoading: useCallback(() => isLoading(), []),
		addLoadingMessage: useCallback((message) => addLoadingMessage(message), []),
		removeSaveModal: useCallback(() => removeSaveModal(), []),
	};

	return <SaveContext.Provider value={contextValue}>{children}</SaveContext.Provider>;
};

export { SaveContext, SaveProvider };
