import { Button, Modal } from "../../MaterialsUI";
import React, { useContext, useState } from "react";
import { CompetenciesToAlignProvider, ErrorContext, SaveContext } from "../../../_lib/contexts";
import { useUpdateCompetencyAlignment } from "../../../_hooks/useStandardsAlignment";
import useAuth from "../../../_hooks/useAuth";
import { CompetencyAlignmentModal } from "..";
import { sanitizeCompetencyAlignment } from "../../../_helpers/formUtils";

function AlignedCompetenciesSection({ isNightTheme, alignmentData, version, handleClick, currentStandardState }) {
	const { addError, isError, setIsErrorState } = useContext(ErrorContext);
	const { isSaved, isLoading, addLoadingMessage, loading, removeSaveModal } = useContext(SaveContext);
	const [showEditCompetencyAlignmentModal, setShowEditCompetencyAlignmentModal] = useState(false);
	const { currentUser } = useAuth();
	const { mutateAsync: resetAlignment } = useUpdateCompetencyAlignment();
	const currentStandardVersion = alignmentData && alignmentData[0].StandardVersion;
	const competencyDescription = alignmentData && alignmentData[0].CompDescriptions;

	async function handleResetOrEdit(competencies, newVersion) {
		if (showEditCompetencyAlignmentModal) setShowEditCompetencyAlignmentModal(!showEditCompetencyAlignmentModal);
		isLoading();
		competencies ? addLoadingMessage("Editing Alignment...") : addLoadingMessage("Reseting Alignment...");

		const preppedStandards = {
			Version: newVersion || currentStandardVersion,
			Path: alignmentData[0].StandardCode,
		};
		const body = sanitizeCompetencyAlignment(preppedStandards, competencies);
		const standard_uuid = alignmentData[0].StandardId;
		const map_key = alignmentData[0].FrameworkId;
		const standard_rev = alignmentData[0].StandardEntityRevision;
		const update = newVersion > currentStandardVersion ? true : false;

		const options = {
			headers: { "X-Arjuna-Initiator": currentUser.name },
			body,
			map_key,
			standard_uuid,
			standard_rev,
			update,
		};

		try {
			await resetAlignment(options);
		} catch (e) {
			setIsErrorState();
			addError(e.message, e.statusCode);
			removeSaveModal();
		}

		if (!loading && !isError) {
			setTimeout(() => isSaved(), 1500);
		}
	}

	return (
		<>
			<div className="d-flex justify-content-between w-full pl-2 pr-4 py-2 text">
				<div>
					{competencyDescription?.map((compDesc, index) => {
						return (
							<p className="mb-0 font-400 py-1" key={index}>
								- {compDesc.Description}
							</p>
						);
					})}
				</div>
				<div className="d-flex">
					<Button
						className={`pointer text btn rounded-input border ${
							isNightTheme ? "border-white" : "border-black"
						} border-w-1 text-capitalize shadow-none text-shadow-1 mr-3`}
						onClick={() => {
							handleClick();
							setShowEditCompetencyAlignmentModal(true);
						}}>
						Edit
					</Button>
					<Button
						className={`pointer text btn rounded-input border ${
							isNightTheme ? "border-white" : "border-black"
						} border-w-1 text-capitalize shadow-none text-shadow-1`}
						onClick={() => {
							handleResetOrEdit();
						}}>
						Reset
					</Button>
				</div>
				<div
					className={`border-rounded ${
						version != currentStandardVersion ? "bg-red" : "bg-muted"
					} px-1 font-400 text-sm cursor-default position-absolute top-1 right-2`}>
					v{currentStandardVersion}
				</div>
			</div>
			<Modal show={showEditCompetencyAlignmentModal}>
				<CompetenciesToAlignProvider>
					<CompetencyAlignmentModal
						show={showEditCompetencyAlignmentModal}
						setShow={setShowEditCompetencyAlignmentModal}
						currentStandard={currentStandardState}
						alignmentData={alignmentData}
						handleResetOrEdit={handleResetOrEdit}
						edit
					/>
				</CompetenciesToAlignProvider>
			</Modal>
		</>
	);
}

export default React.memo(AlignedCompetenciesSection);
