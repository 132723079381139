export function ReactSelectTheme(isNightTheme, menuHeight) {
	const selectFocus = {
		control: (base, state) => ({
			...base,
			boxShadow: state.isFocused ? 0 : 0,
			borderColor: isNightTheme
				? "#6200ee"
				: state.isFocused && isNightTheme
					? "#c7c7c7"
					: state.isFocused && !isNightTheme
						? "#6200ee"
						: "#c7c7c7",
			backgroundColor: isNightTheme ? "#1c1f25" : "#FFFFFF",
			color: isNightTheme ? "#FFFFFF" : "#1c1f25",
		}),
		placeholder: (base) => ({
			...base,
			color: isNightTheme ? "rgb(170, 170, 170)" : "#1c1f25",
		}),
		singleValue: (base) => ({
			...base,
			color: isNightTheme ? "rgb(170, 170, 170)" : "#1c1f25",
		}),
		menuList: (base) => ({
			...base,
			color: isNightTheme ? "#c7c7c7" : "#1c1f25",
			backgroundColor: isNightTheme ? "#34383d" : "#FFFFFF",
			height: menuHeight,
		}),
		option: (base, state) => ({
			...base,
			backgroundColor: state.isSelected ? "#6200ee" : "rgba(0, 0, 255, 0)",
		}),
		multiValue: (base) => ({
			...base,
			backgroundColor: isNightTheme ? "#6200ee" : "#c7c7c7",
			color: isNightTheme ? "#FFFFFF" : "#1c1f25",
		}),
		multiValueLabel: (base) => ({
			...base,
			color: isNightTheme ? "#FFFFFF" : "#1c1f25",
		}),
	};

	return selectFocus;
}
