import React, { useContext, useEffect } from "react";
import { useInView } from "react-intersection-observer";
import Layout from "./Layout";
import GridLayout from "./GridLayout";
import ContentLayout from "./ContentLayout";
// import data from "../data/competencies-transitive-tree.json";
import { CompetencyProperties } from "../_components/Competencies";
import { useCompetencies } from "../_hooks/useCompetencies";
import { CompetencyContext, INITIAL_COMP_CONST } from "../_lib/contexts/CompetencyContext";
import { CompetencyRootContext } from "../_lib/contexts/CompetencyRootContext";
import { NewlyTouchedContext } from "../_lib/contexts/NewlyTouchedContext";
import { ErrorModal, Tree } from "../_components/UI";
import { AppConstants } from "../_lib/constants";
import { addToLocalStorage, getTitleFromTuple } from "../_helpers/functions";
import useAuth from "../_hooks/useAuth";

export default function Authoring() {
	// eslint-disable-next-line no-unused-vars
	const [newlyTouched] = useContext(NewlyTouchedContext);
	const { startSessionRefreshTimer, fetchRefresh, currentUser, myInterval } = useAuth();
	const { competency, setCompetency, competencies } = useContext(CompetencyContext);
	const [rootCompetency] = useContext(CompetencyRootContext);
	const { data, error, isFetchingNextPage, isFetchingPreviousPage, fetchNextPage, fetchPreviousPage, hasNextPage, hasPreviousPage } =
		useCompetencies();
	const rootTitle = getTitleFromTuple(rootCompetency);
	const timePassed = Date.now() - currentUser?.timestamp;
	// eslint-disable-next-line no-undef
	const activeRefresh = AppConstants.activeRefresh;
	const { ref, inView } = useInView();
	const { ref: upperRef, inView: upperInView } = useInView();

	useEffect(() => {
		if (!isFetchingNextPage || !isFetchingPreviousPage) {
			if (timePassed < 10) {
				return;
			}
			addToLocalStorage("LoggedInToken", "timestamp", Date.now());
			if (activeRefresh === "true") {
				fetchRefresh();

				if (!myInterval) {
					startSessionRefreshTimer();
				}
			}
		}
	}, []);

	useEffect(() => {
		if (!isFetchingNextPage || !isFetchingPreviousPage) {
			if (newlyTouched) {
				setCompetency(newlyTouched);
			} else if (rootCompetency?.Children.length > 0 && competency.Key == INITIAL_COMP_CONST) {
				setCompetency(rootCompetency.Children[0]);
			}
		}
	}, [rootCompetency]);

	useEffect(() => {
		if (inView && hasNextPage) {
			fetchNextPage();
		}
	}, [fetchNextPage, inView]);

	useEffect(() => {
		if (upperInView && hasPreviousPage) {
			fetchPreviousPage();
		}
	}, [fetchPreviousPage, upperInView]);

	return (
		<Layout is_lesson page_name="Graph Authoring">
			{error ? (
				<ErrorModal error={error} />
			) : (
				<>
					<GridLayout>
						<Tree
							path={data?.pages}
							group={rootTitle.Text}
							competencies={true}
							innerRef={ref}
							upperRef={upperRef}
							hasNextPage={hasNextPage}
							loadingNextPage={isFetchingNextPage}
							hasPrevPage={hasPreviousPage}
							loadingPrevPage={isFetchingPreviousPage}
							error={error}
							isError={!!error}
						/>
					</GridLayout>
					<ContentLayout>
						{/* Temporary Fix to figure out pagination in graph visualization */}
						{/* <CompetenciesGraphContainer path={rootCompetency.Children} loading={loading} /> */}
						<CompetencyProperties data={competencies} />
					</ContentLayout>
				</>
			)}
		</Layout>
	);
}
