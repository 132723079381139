import React from "react";
import { Button } from "../MaterialsUI";

function SearchBar({ className, input_className, placeholder }) {
	function handleSearch(e) {
		e.preventDefault();
	}

	return (
		<form className="form-inline my-lg-0">
			<div className="d-flex flex-column">
				<div>
					<input className={`form-control ${input_className}`} type="search" placeholder={placeholder} aria-label="Search" />
					<Button
						float
						type="submit"
						material_icons="search"
						className={`${className} btn-outline-none no-hover btn-hover text border-none`}
						onClick={(e) => handleSearch(e)}
					/>
				</div>
			</div>
		</form>
	);
}

export default SearchBar;
