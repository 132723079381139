import React from "react";

function CompetenceyNote({ note, date, peer, newNote = false }) {
	return (
		<>
			<span className={`font-400 text-base ${newNote && "triad"}`}>{note}</span>
			<span className={`font-300 text-sm ${newNote && "triad"}`}>{`Submitted ${date} by ${peer}`}</span>
		</>
	);
}

export default CompetenceyNote;
