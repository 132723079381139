import React from "react";
import PropertiesBox from "./PropertiesBox";
import CompetenceyNote from "./CompetencyNote";
import Tag from "../../MaterialsUI/Tag";
import { readableDate, readableDateWithTime, getTitleFromTuple, getParent, getDefinitionFromTuple } from "../../../_helpers/functions";
import useTheme from "../../../_hooks/useTheme";

function CompetencyPropertiesCard({ data, apiData }) {
	const parent = getParent(apiData, data.Key);
	const parentTitle = getTitleFromTuple(parent);
	const date = readableDate(data?.LastModified);
	const { isNightTheme } = useTheme();

	const definition = getDefinitionFromTuple(data)?.Text;
	const titleData = getTitleFromTuple(data);
	const label = titleData?.Text;
	const language = titleData?.Language;
	const peopleID = data?.PeopleId || "nope";
	const creator = data?.Creator;
	const tags = data?.Tags;
	const notes = data?.Notes;
	const modifiedBy = data?.LastModifiedBy;

	return (
		<div className={`competencey-scope-card ${!isNightTheme && "bg-figma-gray"} shadow-1 cursor-default`}>
			<header className={`d-flex flex-column border-bottom-1 bg-color-light-purple border-top-rounded s-text w-full py-2 px-1`}>
				<div className="d-flex">
					<span data-testid="people_id" className="font-700 mr-2">
						{!peopleID ? "" : peopleID}
					</span>
					<span className="font-700 text-capitalize">{label}</span>
				</div>
				<span className="font-400 text-sm">
					{data ? `${modifiedBy ? `Modified on ${date} by ${modifiedBy}` : `Created on ${date} by ${creator}`}` : "N/A"}
				</span>
			</header>
			<div className=" mt-3 w-full">
				<div className="d-flex w-full">
					<PropertiesBox label="Title" centered className="text-capitalize text" width_class={`w-full s-text`}>
						{label} {language}
					</PropertiesBox>
					<PropertiesBox label="Definition" centered className="text-capitalize text" width_class={`w-full s-text`}>
						{definition}
					</PropertiesBox>
				</div>
				<div className="d-flex mt-2">
					<PropertiesBox label="Transitive Parent" centered className="text" width_class={`w-full s-text`}>
						{/* Temp Fix until root domains are seeded */}
						<div className="d-flex justify-content-center align-items-center">{!parent ? "Math K-12" : parentTitle.Text}</div>
					</PropertiesBox>
					<PropertiesBox label="Tags" centered width_class={`w-full overflow-y max-h-100 s-text`}>
						<div className="grid">
							{tags?.length > 0
								? tags.map((tag, index) => (
										<React.Fragment key={index}>
											<Tag id="count">{tag.Text}</Tag>
										</React.Fragment>
									))
								: ""}
						</div>
					</PropertiesBox>
				</div>
				<div className=" mt-2 mb-3">
					<PropertiesBox label="Internal Notes" width_class={`max-h-90 overflow-y s-text`}>
						<div className="d-flex flex-column text w-full ">
							{notes?.length > 0
								? notes.map((note, index) => {
										const datePosted = readableDateWithTime(note?.Timestamp);
										return (
											<React.Fragment key={index}>
												<CompetenceyNote peer={note.UserId} date={datePosted} note={note.Text} />
											</React.Fragment>
										);
									})
								: "None"}
						</div>
					</PropertiesBox>
				</div>
			</div>
		</div>
	);
}

export default CompetencyPropertiesCard;
