import { useMemo, useEffect } from "react";
import ReactDOM from "react-dom";

export default function Portal({ children, id = "modal" }) {
	let modalRoot = document.getElementById(id);

	if (!modalRoot) {
		modalRoot = document.createElement("div");
		modalRoot.setAttribute("id", id);
		document.body.appendChild(modalRoot);
	}
	const el = useMemo(() => document.createElement("div"), []);
	useEffect(() => {
		modalRoot.appendChild(el);

		return () => modalRoot.removeChild(el);
	}, []);
	return ReactDOM.createPortal(children, el);
}
