import React, { useState, createContext } from "react";

const InitialCompetency = null;

const NewlyTouchedContext = createContext([{}, () => {}]);

const NewlyTouchedProvider = ({ children }) => {
	// Hard Coded Iitaial State for Default
	const [state, setState] = useState(InitialCompetency);

	return <NewlyTouchedContext.Provider value={[state, setState]}>{children}</NewlyTouchedContext.Provider>;
};

export { NewlyTouchedContext, NewlyTouchedProvider };
