/* http://http://daemonite.github.io/material/docs/4.1/material/progress/ */

import React from "react";
import Modal from "./Modal";

function LoadingCircle({ loading, modal = true }) {
	const Spinner = () => (
		<div id="loading_cirlce" data-testid="loading_circle" className="progress-circular">
			<div className="progress-circular-wrapper">
				<div className="progress-circular-inner">
					<div className="progress-circular-left">
						<div className="progress-circular-spinner"></div>
					</div>
					<div className="progress-circular-gap"></div>
					<div className="progress-circular-right">
						<div className="progress-circular-spinner"></div>
					</div>
				</div>
			</div>
		</div>
	);
	return modal ? (
		<Modal show={loading}>
			<Spinner />
		</Modal>
	) : (
		<Spinner />
	);
}

export default LoadingCircle;
