import React, { createContext, useCallback, useState } from "react";

const CancelContext = createContext({
	cancelData: null,
	isCancel: null,
	setIsCancelState: () => {},
	addCancelData: () => {},
	resetCancelState: () => {},
});

const CancelCompetencyProvider = ({ children }) => {
	const [cancelData, setCancelData] = useState(null);
	const [isCancel, setIsCancel] = useState(false);

	const resetCancelState = () => {
		setCancelData(null);
		setIsCancel(false);
	};
	const addCancelData = (data) => setCancelData(data);
	const setIsCancelState = () => setIsCancel(true);

	const contextValue = {
		cancelData,
		isCancel,
		addCancelData: useCallback((data) => addCancelData(data), []),
		resetCancelState: useCallback(() => resetCancelState(), []),
		setIsCancelState: useCallback(() => setIsCancelState(), []),
	};

	return <CancelContext.Provider value={contextValue}>{children}</CancelContext.Provider>;
};

export { CancelContext, CancelCompetencyProvider };
