/* http://http://daemonite.github.io/material/docs/4.1/material/navigation-drawer/ */

import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { removeBlankDrawer } from "../../../_helpers/functions";

function Drawer({ children, header, sub_header, sub_children, sub_to_bottom, nav_id, right, top, bottom, temporary }) {
	// This will remove a class that 'bumps' the body over for the drawer, if drawer is temporary and the route is changed
	const location = useLocation();
	useEffect(() => {
		const routeChange = location;
		if (!!routeChange) {
			removeBlankDrawer();
		}
	}, [location]);

	return (
		<div
			aria-hidden="true"
			className={`navdrawer ${temporary && "navdrawer-temporary"} ${right && "navdrawer-right"} ${top && "navdrawer-top"} ${
				bottom && "navdrawer-bottom"
			}`}
			id={nav_id}
			tabIndex="-1">
			<div className="navdrawer-content bg-secondary-white">
				{header && <div className="navdrawer-header">{header}</div>}
				{children}
				{sub_header && (
					<>
						<div className={sub_to_bottom && `position-absolute bottom-0`} style={{ width: "100%" }}>
							<div className="navdrawer-divider"></div>
							<p className="navdrawer-subheader text cursor-default">{sub_header}</p>
							<ul className="navdrawer-nav">{sub_children}</ul>
						</div>
					</>
				)}
			</div>
		</div>
	);
}

export default React.memo(Drawer);
