import React, { useContext, useReducer, useEffect } from "react";
import { determineParent } from "../../_helpers/functions";
import { LearningPathContext } from "../../_lib/contexts/LearningPathContext";
import { learningPathInitialState, treeReducer } from "../../_reducers/treeReducer";
import { MoreVert } from "../UI";

export const ContentItem = ({ data, depth, className }) => {
	const [state, dispatch] = useReducer(treeReducer, learningPathInitialState);
	const [LearningState, setState] = useContext(LearningPathContext);
	function handleOpen(name) {
		dispatch({
			type: "OPEN",
			name,
		});
	}

	function handleClose(item) {
		dispatch({
			type: "CLOSE",
			item,
		});
	}

	if (!data) {
		return null;
	}

	useEffect(() => {
		const parent = determineParent(data, LearningState.name);
		const grandparent = determineParent(data, parent);
		const greatGrandparent = determineParent(data, grandparent);

		handleOpen(LearningState.name);

		if (greatGrandparent) {
			handleOpen(greatGrandparent);
		}
		if (grandparent) {
			handleOpen(grandparent);
		}
		if (parent) {
			handleOpen(parent);
		}
		if (LearningState.children) {
			handleOpen(LearningState.name);
		}
	}, [LearningState]);

	return (
		<>
			{Array.isArray(data) ? (
				data.map((item, idx) => {
					return (
						<div key={idx}>
							<div
								className={`${className ?? ""} item ${depth === 0 && "mt-2 font-bold "} ${depth == 1 && "font-900"}`}
								data-depth={depth}>
								<div className={` ${item.name == LearningState.name && "selected-item w-full"}`} id={`${idx}_${depth}`}>
									<MoreVert />
									<div className="d-flex align-items-center">
										{!state.currentItem.includes(item.name) ? (
											<button
												data-testid="open"
												className={`btn-outline-* pointer border-0 ${!item.children && "cursor-default"}`}
												onClick={() => handleOpen(item.name)}>
												<div className="block">
													<i
														className={`material-icons ${
															item.name == LearningState.name && "text-white"
														} text ${!item.children && "invisible"}`}>
														add
													</i>
												</div>
											</button>
										) : (
											state.currentItem.includes(item.name) && (
												<button
													data-testid="close"
													className={`btn-outline-* pointer border-0 ${!item.children && "cursor-default"}`}
													onClick={() => handleClose(item.name)}>
													<div className="block">
														<i
															className={`material-icons ${
																item.name == LearningState.name && "text-white"
															} text ${!item.children && "invisible"}`}>
															remove
														</i>
													</div>
												</button>
											)
										)}
										<div className="pointer" onClick={() => setState(item)}>
											{item.name}
										</div>
									</div>
								</div>
							</div>
							{state.currentItem.includes(item.name) && item.children && (
								<ContentItem id={item.name} data={item.children} depth={depth + 1} />
							)}
						</div>
					);
				})
			) : (
				<div className="list-item" data-depth={depth}>
					<div className={` ${data.name == LearningState.name && "selected-item w-full"}`}>
						{data.name}
						{data.children && <ContentItem data={data.children} depth={depth + 1} />}
					</div>
				</div>
			)}
		</>
	);
};
