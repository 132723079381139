import "./styles/index.scss";

/* components */
import MaterialWrapper from "./_components/Wrappers/MaterialWrapper";
import Routes from "./_routes/Routes";

/* node modules for vendor libraries */
import "../node_modules/jquery/dist/jquery";
import "../node_modules/bootstrap/dist/js/bootstrap";
import "../node_modules/djibe-material/js/material";
import "../node_modules/djibe-material/css/material.css";
import "reactflow/dist/style.css";
// or if you just want basic styles
// import 'reactflow/dist/base.css';

/* react things */
import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import Providers from "./_lib/Providers";

function App() {
	return (
		<Router>
			<Providers>
				<MaterialWrapper>
					<Routes />
				</MaterialWrapper>
			</Providers>
		</Router>
	);
}

export default App;
