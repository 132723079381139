import React, { useContext, useEffect } from "react";
import { SaveContext } from "../../../_lib/contexts";
import { Modal } from "../../MaterialsUI";

function SaveModal({ className, loading, loadingMessage }) {
	const { saved, loading: load, loadingMessage: loadMessage, removeSaveModal } = useContext(SaveContext);

	useEffect(() => {
		if (saved == true) {
			setTimeout(() => {
				removeSaveModal();
			}, 2000);
		}
	}, [saved]);

	return (
		<Modal id="saving_modal" show={loading || load} className="d-flex text">
			<div className={className ?? "background rounded p-5 text text-lg"}>
				{(loading || load) && !saved ? (
					<span className="mt-1" data-testid="loading-message">
						{loadingMessage ? loadingMessage : loadMessage ? loadMessage.message : "Loading..."}
					</span>
				) : (
					<div className="d-flex flex-column">
						<div className="d-flex justify-content-center align-content-center mx-5">
							<i className="material-icons mr-2 mt-1">check_circle</i>
							<span className="mt-1 text-lg">Changes Saved</span>
						</div>
					</div>
				)}
			</div>
		</Modal>
	);
}

export default SaveModal;
