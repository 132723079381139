import React, { useState, createContext, useCallback } from "react";

const ErrorContext = createContext({
	error: null,
	isError: null,
	setIsErrorState: () => {},
	addError: () => {},
	removeError: () => {},
});

const ErrorProvider = ({ children }) => {
	const [error, setError] = useState(null);
	const [isError, setIsError] = useState(false);

	const removeError = () => {
		setError(null);
		setIsError(false);
	};
	const addError = (message, statusCode) => setError({ message, statusCode });
	const setIsErrorState = () => setIsError(true);

	const contextValue = {
		error,
		isError,
		addError: useCallback((message, statusCode) => addError(message, statusCode), []),
		removeError: useCallback(() => removeError(), []),
		setIsErrorState: useCallback(() => setIsErrorState(), []),
	};

	return <ErrorContext.Provider value={contextValue}>{children}</ErrorContext.Provider>;
};

export { ErrorContext, ErrorProvider };
