import React, { useContext, useReducer, useState } from "react";
import Select from "react-select";
import { handleFocus, handleInputChange, sanitizeStandards, validateInput } from "../../../_helpers/formUtils";
import useTheme from "../../../_hooks/useTheme";
import { alignmentDocumentFormInitialState, formReducer } from "../../../_reducers/formReducer";
import { Button, Input } from "../../MaterialsUI";
import { ReactSelectTheme } from "../../UI";
import { useAddStandardsAlignment, useStandardFrameworks, useStandardsAlignments } from "../../../_hooks/useStandardsAlignment";
import { ErrorContext, SaveContext } from "../../../_lib/contexts";
import useAuth from "../../../_hooks/useAuth";

function NewAlignmentDocumentForm({ show, setShow }) {
	const [formState, dispatch] = useReducer(formReducer, alignmentDocumentFormInitialState);
	const { addError, setIsErrorState } = useContext(ErrorContext);
	const { isSaved, isLoading, addLoadingMessage, loading: load, removeSaveModal } = useContext(SaveContext);
	const { currentUser } = useAuth();
	const {
		data: frameworkData,
		isLoading: frameworkIsLoading,
		isError: frameworkIsError,
		error: frameworkError,
	} = useStandardFrameworks();
	const {
		mutateAsync: addAlignmentDocument,
		isLoading: alignmentDocumentIsLoading,
		isError: alignmentDocumentIsError,
		error: alignmentDocumentError,
	} = useAddStandardsAlignment();
	const { data: alignmentDocData } = useStandardsAlignments();
	const [showError, setShowError] = useState(false);
	const { isNightTheme, isSunsetTheme } = useTheme();
	const [selectedFramework, setSelectedFramework] = useState();

	const error = frameworkError || alignmentDocumentError;
	const loading = frameworkIsLoading || alignmentDocumentIsLoading || load;
	const isError = frameworkIsError || alignmentDocumentIsError;

	let baseStateStandardSets = [];
	let alignmentLevels = [];

	frameworkData && !loading
		? frameworkData.map((option, index) => {
				if (!alignmentDocData?.some((doc) => doc.Key == option.FrameworkId)) {
					baseStateStandardSets.push({
						key: { index },
						label: `{${option.Code}} ${option.Description}  ©${option.Copyright}`,
						value: option,
					});
				}
			})
		: null;

	selectedFramework
		? selectedFramework.Attributes.setMap.map((option, index) => {
				alignmentLevels.push({
					key: { index },
					label: option.key,
					value: index,
				});
			})
		: null;

	async function onSubmit(e) {
		e.preventDefault();
		let isFormValid = true;

		for (const name in formState) {
			const field = formState[name];
			const { value } = field;
			const { hasError, error: formError } = validateInput(name, value);
			if (hasError) {
				isFormValid = false;
			}
			if (name) {
				dispatch({
					type: "UPDATE_FORM",
					data: {
						name,
						value,
						hasError,
						error: formError,
						touched: true,
						isFormValid,
					},
				});
			}
		}
		if (!isFormValid) {
			setShowError(true);
		} else {
			setShow(!show);
			isLoading();
			addLoadingMessage("Creating Your New Document...");
			const newStandardAlignmentDoc = sanitizeStandards(formState, currentUser.name);
			const options = {
				headers: { "X-Arjuna-Initiator": currentUser.name },
				body: newStandardAlignmentDoc,
			};

			try {
				await addAlignmentDocument(options);
			} catch (e) {
				setIsErrorState();
				addError(e.message, e.statusCode);
				removeSaveModal();
			}
			setTimeout(() => isSaved(), 1500);
		}
	}

	return (
		<form
			className="d-flex flex-column justify-content-start needs-validation h-full w-full"
			noValidate
			onSubmit={onSubmit}
			data-testid="alignment-form">
			<div className={`${isSunsetTheme ? "text-purple" : "text"} h4 font-900 mb-2 tracing-1`}>New Alignment Document</div>
			<div
				id="error_message"
				className={
					showError || ((error || isError) && !formState.isFormValid) ? "bg-danger my-1 rounded p-2" : "invisible my-1 p-2"
				}>
				{error ? error.message : "Please fill all the fields correctly"}
			</div>
			<Input
				id="titles"
				name="titles"
				placeholder="E.g., 2022 Oklahoma State Standards Alignment"
				label="*Title (title case)"
				label_class_name={`${isSunsetTheme ? "text-purple" : "text"} text-lg font-700 mb-1 tracing-1`}
				input_class_name={`${
					isNightTheme
						? "background border-w-1 border-rounded-sm border-primary border-focus-gray text"
						: "border-gray border-focus-primary bg-white"
				}  pl-2 text-base`}
				onBlur={(e) => {
					handleFocus("Title", e.target.value, dispatch, formState);
				}}
				onChange={(e) => {
					handleInputChange("Title", e.target.value, dispatch, formState);
				}}
				hasError={formState.Title.touched && formState.Title.hasError}
				small_text_id="error"
				small_text={formState.Title.touched && formState.Title.hasError && formState.Title.error}
			/>
			<div className="mt-4">
				<Input
					id="description"
					name="description"
					placeholder="E.g., Alignment of 2022 Oklahoma Math State Standards for K-12. Pre-K is not included."
					label="*Description"
					label_class_name={`${isSunsetTheme ? "text-purple" : "text"} text-lg font-700 mb-1 tracing-1`}
					input_class_name={`${
						isNightTheme
							? "background border-rounded-sm border-w-1 border-primary border-focus-gray text"
							: "border-gray border-focus-primary bg-white"
					}  pl-2 text-base`}
					onBlur={(e) => {
						handleFocus("Description", e.target.value, dispatch, formState);
					}}
					onChange={(e) => {
						handleInputChange("Description", e.target.value, dispatch, formState);
					}}
					hasError={formState.Description.touched && formState.Description.hasError}
					small_text_id="error"
					small_text={formState.Description.touched && formState.Description.hasError && formState.Description.error}
				/>
			</div>
			<div className="mt-4">
				<span className={`${isSunsetTheme ? "text-purple" : "text"} text-lg font-700 mb-1 tracing-1`}>
					*Base State Standard Set
				</span>
				<Select
					className="text-capitalize"
					inputId="base_state_standard_selector"
					aria-labelledby="base-state-standard-selector"
					placeholder="Select a Base State Standard Set..."
					noOptionsMessage={() =>
						"All Standards Sets are used by other alignment documents. Click the Select Existing Documents dropdown and select the Alignment Document you want to view or edit."
					}
					required
					isSearchable
					menuShouldBlockScroll
					options={baseStateStandardSets}
					styles={ReactSelectTheme(isNightTheme, "200px")}
					onChange={(e) => {
						handleInputChange("BaseStateStandard", e.value, dispatch, formState);
						setSelectedFramework(e.value);
					}}
				/>
				<small
					id="base_state_standard_error"
					data-testid="base-state-standard-small"
					role="alert"
					className={
						formState.BaseStateStandard.touched && formState.BaseStateStandard.hasError ? "invalid-feedback" : "form-text muted"
					}>
					{formState.BaseStateStandard.touched && formState.BaseStateStandard.hasError ? formState.BaseStateStandard.error : null}
				</small>
			</div>
			<div className="mt-4">
				<span className={`${isSunsetTheme ? "text-purple" : "text"} text-lg font-700 mb-1 tracing-1`}>*Alignment Level</span>
				<Select
					className="text-capitalize"
					inputId="level_selector"
					aria-labelledby="level-selector"
					placeholder="Choose a Level..."
					isSearchable
					required
					menuShouldBlockScroll
					options={alignmentLevels}
					styles={ReactSelectTheme(isNightTheme, "115px")}
					onChange={(e) => {
						handleInputChange("Level", e.value, dispatch, formState);
					}}
				/>
				<small
					id="level_error"
					data-testid="level-small"
					role="alert"
					className={formState.Level.touched && formState.Level.hasError ? "invalid-feedback" : "form-text muted"}>
					{formState.Level.touched && formState.Level.hasError ? formState.Level.error : null}
				</small>
			</div>
			<small className="text font-italic">*required</small>
			<div className="d-flex justify-content-center">
				<Button className="rounded-input btn-white bg-white mr-4" type="submit" data_testid="save_btn">
					Save <i className="material-icons ml-2">check_circle</i>
				</Button>
				<Button
					className="rounded-input btn-white bg-white ml-4"
					data_testid="exit_btn"
					onClick={() => {
						setShow(!show);
					}}>
					Cancel <i className="material-icons ml-2">highlight_off</i>
				</Button>
			</div>
		</form>
	);
}

export default NewAlignmentDocumentForm;
