import React, { useCallback } from "react";

const CreateChildCompetencyBtn = ({showModal}) => {
    
    const handleCreateChildCompetencyClick = useCallback(() => {
        showModal(); // show the modal (sets show to true in parent component)
    })  

    const buttonLabel = "Create Child";

    return (
        <React.Fragment>
            <button onClick={() => handleCreateChildCompetencyClick()} className="create-child-competency-btn">
                { buttonLabel }
                <i className="material-icons outline-black">add</i>
            </button>
        </React.Fragment>
    )
}

export default CreateChildCompetencyBtn;