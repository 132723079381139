import React from "react";
import Select, { components } from "react-select";
import useTheme from "../../_hooks/useTheme";

function ThemeSelector() {
	const { isLightTheme, isSunsetTheme, isNightTheme, selectTheme } = useTheme();

	const customStyles = {
		control: (provided, state) => ({
			...provided,
			border: state.isFocused ? "4px" : "none",
			borderColor: state.isFocused ? "#eeeeee" : "none",
			outline: "none",
			margin: "0px 16px",
			backgroundColor: "none",
			cursor: "pointer",
			boxShadow: state.isFocused ? 1 : 0,
		}),
		placeholder: (provided) => ({
			...provided,
			color: isNightTheme ? "white" : "black",
		}),
		singleValue: (provided) => ({
			...provided,
			color: isNightTheme ? "white" : "black",
		}),
		indicatorSeparator: () => ({
			display: "hidden",
		}),
		menuList: (provided) => ({
			...provided,
			backgroundColor: isSunsetTheme ? "#eeeeee" : isNightTheme ? "#1c1f25" : "white",
			color: isNightTheme ? "white" : "black",
		}),
		option: (provided, state) => ({
			...provided,
			backgroundColor: state.isSelected ? "#6200ee" : "rgba(0, 0, 255, 0)",
			cursor: "pointer",
		}),
	};

	const options = [
		{
			label: "Light Mode",
			value: "light",
			icon: "light_mode",
		},
		{
			label: "Dark Mode",
			value: "night",
			icon: "dark_mode",
		},
		{
			label: "Sunset Theme",
			value: "sunset",
			icon: "dark_mode",
		},
	];

	const Option = ({ ...props }) => (
		<components.Option {...props}>
			<i className="material-icons mr-3">{props.value == "light" ? "light_mode" : props.value == "night" ? "dark_mode" : "flare"}</i>{" "}
			{props.label}
		</components.Option>
	);

	const ValueContainer = ({ ...props }) => (
		<>
			<i className={`material-icons mr-3 ${isNightTheme ? "text-white" : "text-black"}`}>
				{isLightTheme ? "light_mode" : isNightTheme ? "dark_mode" : "flare"}
			</i>
			<components.ValueContainer {...props} />
		</>
	);

	return (
		<Select
			isSearchable={false}
			menuShouldBlockScroll={true}
			components={{ Option, ValueContainer }}
			options={options}
			styles={customStyles}
			placeholder={isLightTheme ? "Light Mode" : isNightTheme ? "Dark Mode" : "Sunset Theme"}
			menuPlacement={"top"}
			onChange={(e) => selectTheme(e.value)}
		/>
	);
}

export default ThemeSelector;
