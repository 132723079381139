import { HttpRequestException } from "../_errors_/httpRequestException";
import useAuth from "./useAuth";

export function useFetchWrapper() {
	const { logout } = useAuth();

	async function handleResponse(response) {
		let responseBody;

		if (response.status == 204) {
			return response;
		}

		responseBody = await response.json();

		if (!response.ok) {
			if ([401, 403].includes(response.status)) {
				// auto logout if 401 Unauthorized or 403 Forbidden response returned from api
				logout();
			}

			throw new HttpRequestException(responseBody.errorMessage, responseBody.errorNum);
		}

		return responseBody;
	}

	function request(method) {
		let response;
		return async (url, body, headers = {}) => {
			const requestOptions = {
				method,
				headers,
			};

			requestOptions.headers["Content-Type"] = "application/json";

			if (body) {
				requestOptions.body = JSON.stringify(body);
			}

			try {
				const response = await fetch(url, requestOptions);
				return handleResponse(response);
			} catch (e) {
				let message = response.statusText || "Could not complete request";
				throw new HttpRequestException(message, response.status);
			}
		};
	}

	return {
		get: request("GET"),
		post: request("POST"),
		put: request("PUT"),
		patch: request("PATCH"),
		delete: request("DELETE"),
	};
}
