import React from "react";

function MaterialWrapper({ children }) {
	return (
		<div className="doc-navbar-temporary">
			<link
				href="https://fonts.googleapis.com/css2?family=Roboto+Mono:ital,wght@0,100;1,100&family=Roboto:ital,wght@0,400;1,300&display=swap"
				rel="stylesheet"
			/>
			<link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet" />
			{children}
		</div>
	);
}

export default MaterialWrapper;
