import React, { useState, createContext } from "react";

const InitialState = null;

/**
 * Current standard's alignment context.
 * If the selected standard has alignments, provides the standard's GUID, path, author, version,
 * and an array of competency keys aligned to the standard.
 */
const StandardContext = createContext([{}, () => {}]);

const StandardProvider = ({ children }) => {
	const [standardDocument, setStandard] = useState(InitialState);

	return <StandardContext.Provider value={[standardDocument, setStandard]}>{children}</StandardContext.Provider>;
};

export { StandardContext, StandardProvider };
