import React, { useReducer, useState, useEffect } from "react";
import { Button, Input } from "../../MaterialsUI";
import { handleInputChange, handleFocus, validateInput } from "../../../_helpers/formUtils";
import useAuth from "../../../_hooks/useAuth";
import { loginInitialState, formReducer } from "../../../_reducers/formReducer";

export default function LoginForm() {
	const { login, loginError, error } = useAuth();
	const [formState, dispatch] = useReducer(formReducer, loginInitialState);
	const [showError, setShowError] = useState(false);
	const [submitted, setSubmitted] = useState(false);
	const emptyInputs = !formState.email.value || !formState.password.value;

	async function onSubmit(e) {
		e.preventDefault();

		let isFormValid = true;

		for (const name in formState) {
			const field = formState[name];
			const { value } = field;
			const { hasError, error } = validateInput(name, value);
			if (hasError) {
				isFormValid = false;
			}
			if (name) {
				dispatch({
					type: "UPDATE_FORM",
					data: {
						name,
						value,
						hasError,
						error,
						touched: true,
						isFormValid,
					},
				});
			}
		}
		if (!isFormValid) {
			setShowError(true);
			setSubmitted(true);
		}

		const credentials = {
			email: e.target.email?.value,
			password: e.target.password?.value,
		};
		await login(credentials);
		setSubmitted(true);
	}

	useEffect(() => {
		let mounted = true;
		mounted && setTimeout(() => submitted && setSubmitted(false), 2500);

		return function cleanupFn() {
			mounted = false;
		};
	}, [submitted]);

	return (
		<>
			<div
				id="error_message"
				data-testid="error_message"
				className={
					(showError && !formState.isFormValid && !emptyInputs && submitted) ||
					(loginError && submitted && !emptyInputs) ||
					(error && submitted && !emptyInputs)
						? "bg-danger my-1 rounded p-2"
						: "invisible my-1 p-2"
				}>
				{!formState.isFormValid
					? "Please fill all the fields correctly"
					: loginError
						? "Sorry, the username and/or password are not recognized"
						: error && submitted
							? "Error"
							: null}
			</div>
			<form id="login_form" onSubmit={onSubmit} className="needs-validation" noValidate>
				<Input
					label="Username"
					type="email"
					id="email_input"
					name="email"
					input_class_name="login-input"
					label_class_name={"text-black"}
					value={formState.email.value}
					onChange={(e) => {
						handleInputChange("email", e.target.value, dispatch, formState);
					}}
					onBlur={(e) => {
						handleFocus("email", e.target.value, dispatch, formState);
					}}
					small_text_id="disclosure"
					small_text={
						formState.email.touched && formState.email.hasError
							? formState.email.error
							: "We'll never share this with anyone else."
					}
					small_text_class_name="login-input-text"
					hasError={formState.email.touched && formState.email.hasError}
					required
				/>
				<Input
					label="Password"
					type="password"
					id="password_input"
					name="password"
					input_class_name="login-input"
					label_class_name={"text-black"}
					value={formState.password.value}
					onChange={(e) => {
						handleInputChange("password", e.target.value, dispatch, formState);
					}}
					onBlur={(e) => {
						handleFocus("password", e.target.value, dispatch, formState);
					}}
					hasError={formState.password.touched && formState.password.hasError}
					small_text_id="error"
					small_text={formState.password.touched && formState.password.hasError && formState.password.error}
					required
				/>
				<Button
					id="login_submit"
					type="submit"
					className="btn-login"
					disabled={!formState.password.value && !formState.email.value}>
					Log In
				</Button>
			</form>
		</>
	);
}
