import React, { useEffect } from "react";
import SearchBar from "./SearchBar";
import useTheme from "../../_hooks/useTheme";
import Button from "./Button";

function ModalContent({
	title,
	setShow,
	closing_text,
	action_text,
	search_bar,
	exit_button,
	children,
	center_title,
	min_width,
	on_action_callback,
}) {
	const { theme } = useTheme();
	useEffect(() => {
		document.addEventListener("keyup", (e) => {
			if (e.code === "Enter") {
				setShow();
			}
		});
	});

	return (
		<div
			className={`${theme == "night" ? "text-white" : "text-black"} modal-component overflow-y background ${
				min_width && "min-w-50p"
			}`}
			onClick={(e) => e.stopPropagation()}>
			<header className="h3 d-flex align-items-center justify-content-between w-full">
				{title && <span className={`d-flex justify-content-center ${center_title && "w-full"}`}>{title}</span>}
				{search_bar && (
					<SearchBar
						placeholder="Search Tree..."
						className="invisible"
						input_className={`background placeholder-dark rounded-input border-w-1 px-2 `}
					/>
				)}
				{exit_button && (
					<button type="button" className="muted pointer border-none bg-transparent" onClick={() => setShow()}>
						<span aria-hidden="true">&times;</span>
					</button>
				)}
			</header>
			<div className={`modal-body ${theme == "night" ? "text-white" : "text-black"}`}>{children}</div>
			<footer>
				{closing_text && (
					<Button id="modal_dismiss" className="btn-primary ml-4" onClick={() => setShow()}>
						{closing_text}
					</Button>
				)}
				{action_text && (
					<Button
						id="modal_action"
						className="btn-primary"
						onClick={() => {
							//override the default 'set show' if an action callback is provided
							if (on_action_callback) on_action_callback();
							if (setShow) setShow();
						}}>
						{action_text}
					</Button>
				)}
			</footer>
		</div>
	);
}

export default ModalContent;
