/* http://http://daemonite.github.io/material/docs/4.1/components/modal/ */

import React, { useRef } from "react";
import Portal from "../../_lib/Portal";
import ModalContent from "./ModalContent";

function Modal({
	id,
	show,
	title,
	setShow,
	dispatch,
	children,
	closing_text,
	action_text,
	search_bar,
	exit_button,
	center_title,
	content,
	min_width,
	on_action_callback,
}) {
	const backdrop = useRef(null);

	if (show) {
		document.body.classList.add("overflow-hidden");
	} else {
		document.body.classList.remove("overflow-hidden");
	}

	return (
		<>
			{((show && children) || (show && content)) && (
				<Portal className="modal-portal" id={id} data-testid="notification-modal">
					<div
						data-testid="backdrop"
						ref={backdrop}
						className={`${show && "active"} backdrop`}
						onClick={() => {
							(dispatch && dispatch()) || (setShow && setShow());
						}}>
						{content ? (
							<ModalContent
								data-testid="modal-content"
								title={title}
								setShow={setShow}
								closing_text={closing_text}
								action_text={action_text}
								search_bar={search_bar}
								exit_button={exit_button}
								center_title={center_title}
								min_width={min_width}
								on_action_callback={on_action_callback}>
								{children}
							</ModalContent>
						) : children ? (
							children
						) : null}
					</div>
				</Portal>
			)}
		</>
	);
}

export default Modal;
