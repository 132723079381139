import React, { useContext } from "react";
import { DocumentEditor } from "../_components/Standards";

import { useStandardsAlignments } from "../_hooks/useStandardsAlignment";
import { StandardsAlignmentDocumentContext } from "../_lib/contexts";
import Layout from "./Layout";

function StandardsAlignmentLayout() {
	const [currentDocument] = useContext(StandardsAlignmentDocumentContext);
	const { data: StandardsAlignmentDocs, isLoading } = useStandardsAlignments();

	return (
		<Layout is_lesson page_name="Standards Alignment">
			{!currentDocument ? (
				<div className="d-flex flex-column justify-content-center align-items-center w-full h-80">
					<h2 className="text h3">
						{!StandardsAlignmentDocs && !isLoading
							? "No alignment documents are available."
							: "Please Select your standards alignment document"}
					</h2>

					{!StandardsAlignmentDocs && !isLoading && <h3 className="text">There&apos;s nothing to see here.</h3>}
				</div>
			) : (
				<div className="d-flex justify-content-center w-full">
					<DocumentEditor data={currentDocument} />
				</div>
			)}
		</Layout>
	);
}

export default React.memo(StandardsAlignmentLayout);
