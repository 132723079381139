import React, { useContext, useEffect, useMemo, useReducer, useState } from "react";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import { handleFocus, handleInputChange, sanitizeCompetencyData, validateInput } from "../../../_helpers/formUtils";
import { flattenTree, getParent, getTitleFromTuple } from "../../../_helpers/functions";
import useAuth from "../../../_hooks/useAuth";
import { useAddCompetency } from "../../../_hooks/useCompetencies";
import { useTags } from "../../../_hooks/useTags";
import useTheme from "../../../_hooks/useTheme";
import { CancelContext, CloneContext, CompetencyContext, ErrorContext, SaveContext } from "../../../_lib/contexts";
import { competencyFormInitialState, formReducer } from "../../../_reducers/formReducer";
import { Button } from "../../MaterialsUI";
import { ConfirmOrCancelModal, GrowingTextBox, ReactSelectTheme } from "../../UI";
import { CompetencyRootContext } from "../../../_lib/contexts";

function CloneForm() {
	const { mutateAsync: addCompetency } = useAddCompetency();
	const { data: tagData, isLoading: tagsIsLoading, isError: tagsIsError } = useTags();

	const [formState, dispatch] = useReducer(formReducer, competencyFormInitialState);

	const { addError, setIsErrorState } = useContext(ErrorContext);
	const { isSaved, isLoading, addLoadingMessage, removeSaveModal } = useContext(SaveContext);
	const { cancelData, isCancel, addCancelData, resetCancelState, setIsCancelState } = useContext(CancelContext);
	const { cloneData, addCloneData, setIsClone, isCloneState, setShowCloneModal, setHideCloneModal, resetCloneState } =
		useContext(CloneContext);
	// Access currently selected Competency
	const { competency, competencies } = useContext(CompetencyContext);
	// root competency (default)
	const [rootCompetency] = useContext(CompetencyRootContext);

	const [showError, setShowError] = useState(false);
	const [showDiscard, setShowDiscard] = useState(false);

	const { currentUser } = useAuth();
	const { isSunsetTheme, isNightTheme } = useTheme();

	let parent = useMemo(() => getParent(competencies.flat(), competency.Key), [competency.Key]);

	const k12Flat = flattenTree(competencies.flat(), true).flat();
	const name = currentUser?.name;
	const parentOptions = [];
	const tagOptions = [];
	const canceledTagOptions = [];
	const clonedTagOptions = [];
	
	// add root competency to beginning of k12 flat array
	k12Flat.unshift(rootCompetency)	
	// default to root if parent is null
	if (parent == null) parent = rootCompetency;
	
	!tagsIsError && tagData
		? tagData?.map((options, index) => {
				tagOptions.push({
					key: { index },
					label: options.Text,
					value: options,
				});
			})
		: null;

	k12Flat.forEach((competency, index) => {
		const title = getTitleFromTuple(competency);
		parentOptions.push({
			key: { index },
			label: `${competency?.PeopleId || ""} ${title?.Text || ""}`,
			value: competency,
		});
	});

	if (isCancel && Array.isArray(cancelData?.Tags.value)) {
		cancelData?.Tags.value.map((options, index) => {
			canceledTagOptions.push({
				key: { index },
				label: options.Text ? options.Text : options,
				value: options,
			});
		});
	}

	cloneData && Array.isArray(cloneData.Tags.value)
		? cloneData.Tags.value.map((options, index) => {
				clonedTagOptions.push({
					key: { index },
					label: options.Text ? options.Text : options,
					value: options,
				});
			})
		: null;

	async function handleSave(body) {
		setHideCloneModal();
		isLoading();
		addLoadingMessage("Saving Competency...");

		const options = {
			headers: { "X-Arjuna-Initiator": currentUser.name },
			body,
		};

		try {
			const saved = await addCompetency(options);
			// reset cancel state
			resetCancelState();
			setTimeout(() => isSaved(), 1500);

			// Open clone modal if needed
			if (isCloneState && saved) {
				addCloneData(saved);
				setShowCloneModal();
			} else {
				resetCloneState();
			}
		} catch (e) {
			setIsErrorState();
			addError(e.message, e.statusCode);
			removeSaveModal();
		}
	}

	function onSubmit(e) {
		e.preventDefault();
		let isFormValid = true;

		for (const name in formState) {
			const field = formState[name];
			const { value } = field;
			const { hasError, error } = validateInput(name, value);
			if (hasError) {
				isFormValid = false;
			}
			if (name) {
				dispatch({
					type: "UPDATE_FORM",
					data: {
						name,
						value,
						hasError,
						error,
						touched: true,
						isFormValid,
					},
				});
			}
		}
		if (!isFormValid) {
			setShowError(true);
		} else {
			const newCompetency = sanitizeCompetencyData(formState, null, name, false, true);
			handleSave(newCompetency);
		}
	}

	useEffect(() => {
		dispatch({
			type: "SET_CLONE_FORM",
			data: {
				Parent: {
					value: parentOptions.filter((option) => option.value.Key == parent?.Key)[0]?.value,
					hasError: false,
					error: "",
					touched: false,
				},
				...cloneData,
			},
		});
	}, []);

	return (
		<>
			{showDiscard ? (
				<ConfirmOrCancelModal
					className="background rounded p-4"
					onClickCancel={() => {
						setShowDiscard(false);
					}}
					onClickConfirm={() => {
						// reset cancel state
						setHideCloneModal();
						resetCancelState();
						resetCloneState();
					}}>
					Confirm that you want to discard the current draft. All edits will be lost.
				</ConfirmOrCancelModal>
			) : (
				<div
					className={`editor shadow-1 p-4 ${!isSunsetTheme ? "background" : "bg-sidekick"}`}
					onClick={(e) => e.stopPropagation()}>
					<form
						className="d-flex flex-column justify-content-start needs-validation h-full w-full"
						noValidate
						onSubmit={onSubmit}>
						<div className={`${!isSunsetTheme ? "text" : "text-white"} h4 font-900 mb-2`}>Create New Sibling Competency</div>
						<div id="error_message" className={showError ? "bg-danger my-1 rounded p-2" : "invisible my-1 p-2"}>
							Please fill all the fields correctly
						</div>
						<GrowingTextBox
							id="titles"
							initial_rows={1}
							placeholder="E.g., Write Numbers From 0-20"
							label="*Title (title case)"
							label_className={`${!isSunsetTheme ? "text" : "text-white"} text-lg font-700 mb-1 tracing-1`}
							className={`${
								isNightTheme
									? "background border-primary border-focus-gray text"
									: "border-gray border-focus-primary bg-white"
							} placeholder-dark-to-light`}
							defaultValue={isCancel ? cancelData?.Title.value : formState?.Title.value}
							onBlur={(e) => {
								handleFocus("Title", e.target.value, dispatch, formState);
							}}
							onChange={(e) => {
								handleInputChange("Title", e.target.value, dispatch, formState);
							}}
							hasError={formState?.Title.touched && formState?.Title.hasError}
							small_text_id="error"
							small_text={formState?.Title.touched && formState?.Title.hasError && formState?.Title.error}
						/>
						<div className="mt-4">
							<GrowingTextBox
								id="definitions"
								initial_rows={1}
								placeholder="E.g., Write Numbers From 0-20. Represent a number of objects with a written numeral 0-20"
								label="*Definition"
								label_className={`${!isSunsetTheme ? "text" : "text-white"} text-lg font-700 mb-1 tracing-1`}
								className={`${
									isNightTheme
										? "background border-primary border-focus-gray text"
										: "border-gray border-focus-primary bg-white"
								} placeholder-dark-to-light`}
								defaultValue={isCancel ? cancelData?.Definition.value : formState?.Definition.value}
								onBlur={(e) => {
									handleFocus("Definition", e.target.value, dispatch, formState);
								}}
								onChange={(e) => {
									handleInputChange("Definition", e.target.value, dispatch, formState);
								}}
								hasError={formState?.Definition.touched && formState?.Definition.hasError}
								small_text_id="error"
								small_text={formState?.Definition.touched && formState?.Definition.hasError && formState?.Definition.error}
							/>
						</div>
						<div className="mt-4">
							<span
								className={`${!isSunsetTheme ? "text" : "text-white"} text-lg font-700 tracing-1`}
								data-comp-key={formState?.Parent?.value?.Key}
								data-comp-rev={formState?.Parent?.value?.Revision}>
								*Transitive Parent
							</span>
							<Select
								className="text-capitalize"
								inputId="parent_selector"
								aria-labelledby="parent-selector"
								isSearchable
								required
								isDisabled={true} // when creating sibling competency, changing transitive parent is disabled	
								menuShouldBlockScroll
								styles={ReactSelectTheme(isNightTheme)}
								options={parentOptions}
								defaultValue={parentOptions.find((option) => option.value.Key == parent?.Key)}
								onChange={(e) => {
									handleInputChange("Parent", e.value, dispatch, formState);
								}}
							/>
							<small
								id="parent_error"
								data-testid="parent-small"
								role="alert"
								className={
									formState?.Parent.touched && formState?.Parent.hasError ? "invalid-feedback" : "form-text muted"
								}>
								{formState?.Parent.touched && formState?.Parent.hasError ? formState?.Parent.error : null}
							</small>
						</div>
						<div className="d-flex flex-column mt-2">
							<span className={`${!isSunsetTheme ? "text" : "text-white"} text-lg font-700 tracing-1`}>Tags</span>
							<CreatableSelect
								isMulti
								isSearchable
								isClearable
								allowCreateWhileLoading
								menuShouldBlockScroll
								aria-labelledby="tag-selector"
								inputId="tag_selector"
								openMenuOnClick={false}
								disabled={tagsIsError}
								isLoading={tagsIsLoading}
								options={tagOptions}
								styles={ReactSelectTheme(isNightTheme, "200px")}
								defaultValue={isCancel ? canceledTagOptions : clonedTagOptions}
								onChange={(e) => {
									handleInputChange(
										"Tags",
										e.map((x) => x.value),
										dispatch,
										formState
									);
								}}
							/>
						</div>
						<div className="d-flex flex-column mt-4">
							<span className={`${!isSunsetTheme ? "text" : "text-white"} text-lg font-700 tracing-1`}>Internal Notes</span>
							<div className="d-flex align-items-center w-full">
								<GrowingTextBox
									id="auto_resize"
									initial_rows={2}
									placeholder="Prompt..."
									className={`${
										isNightTheme
											? "background border-primary border-focus-gray text"
											: "border-gray border-focus-primary bg-white"
									} placeholder-dark-to-light`}
									defaultValue={isCancel ? cancelData?.Note.value[0] : undefined}
									onBlur={(e) => handleFocus("Note", e.target.value, dispatch, formState)}
									onChange={(e) => {
										handleInputChange("Note", e.target.value, dispatch, formState);
									}}
								/>
							</div>
							<span className={`d-flex justify-content-center font-600 ${!isSunsetTheme ? "text" : "text-white"}`}>
								New Note
							</span>
						</div>
						<small className={`${!isSunsetTheme ? "text" : "text-white"} font-italic`}>*required</small>
						<div className="d-flex justify-content-around align-items-center mx-5 h-full">
							<Button
								className="rounded-input btn-white bg-white"
								type="submit"
								data_testid="save_btn"
								onClick={() => resetCloneState()}>
								Save <i className="material-icons ml-2">check_circle</i>
							</Button>
							<Button
								className="rounded-input btn-white bg-white"
								type="submit"
								data_testid="clone_btn"
								disabled={
									(!formState?.Title.value &&
										!formState?.Title.touched &&
										!formState?.Definition.value &&
										!formState?.Definition.touched) ||
									!formState.isFormValid
								}
								onClick={() => {
									// Set the clone data context, and save, then open clone modal
									setIsClone();
								}}>
								Save and Create Sibling <i className="material-icons ml-2">copy_all</i>
							</Button>
							<Button
								className="rounded-input btn-white bg-white"
								data_testid="exit_btn"
								onClick={() => {
									// set the cancelContext data and pop the discard option up
									setIsCancelState();
									addCancelData(formState);
									setShowDiscard(true);
								}}>
								Cancel <i className="material-icons ml-2">highlight_off</i>
							</Button>
						</div>
					</form>
				</div>
			)}
		</>
	);
}

export default CloneForm;
