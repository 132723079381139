import React from "react";
import { ReactFlowProvider } from "reactflow";
import { QueryClientProvider } from "@tanstack/react-query";
import { ErrorModal, SaveModal } from "../_components/UI";
import { AuthProvider } from "../_hooks/useAuth";
import { ThemeProvider } from "../_hooks/useTheme";
import {
	CancelCompetencyProvider,
	CompetencyCloneProvider,
	CompetencyProvider,
	CompetencyRootProvider,
	LearningPathProvider,
	NewlyTouchedProvider,
	StandardsAlignmentDocumentProvider,
	ErrorProvider,
	SaveProvider,
} from "./contexts";

import { queryClient } from "./queryClient";

function Providers({ children }) {
	return (
		<AuthProvider>
			<ThemeProvider>
				<QueryClientProvider client={queryClient}>
					<LearningPathProvider>
						<CompetencyRootProvider>
							<NewlyTouchedProvider>
								<CompetencyProvider>
									<StandardsAlignmentDocumentProvider>
										<CompetencyCloneProvider>
											<ErrorProvider>
												<SaveProvider>
													<CancelCompetencyProvider>
														<ReactFlowProvider>
															{children}
															<ErrorModal />
															<SaveModal />
														</ReactFlowProvider>
													</CancelCompetencyProvider>
												</SaveProvider>
											</ErrorProvider>
										</CompetencyCloneProvider>
									</StandardsAlignmentDocumentProvider>
								</CompetencyProvider>
							</NewlyTouchedProvider>
						</CompetencyRootProvider>
					</LearningPathProvider>
				</QueryClientProvider>
			</ThemeProvider>
		</AuthProvider>
	);
}

export default Providers;
