import React from "react";

function LessonHeaderCompetencyBtn({ data, show, setShow }) {
	return (
		<>
			<button
				disabled={!data?.length}
				className="btn-border pointer bg-color-purple btn rounded-input text-capitalize shadow-1 ml-5 border-none px-3 text-white"
				onClick={() => setShow(!show)}>
				<i className="material-icons">add</i> Add Competency
			</button>
		</>
	);
}

export default LessonHeaderCompetencyBtn;
