import React from "react";
import Header from "./Header";
import StandardsTable from "./StandardsTable";
import { useGetStandards } from "../../../_hooks/useStandardsAlignment";

function DocumentEditor({ data }) {
	const { data: standardsData, isLoading, error } = useGetStandards(data?.Key) || {};
	const status = data?.Status;
	const code = data?.StandardSetId;
	const titleData = data?.Title;
	const title = Array.isArray(titleData) && titleData[0]?.Text;

	return (
		<div className="mt-1 p-2 d-flex justify-content-center">
			<div className="bg-light-offset-purple document-editor shadow-1">
				<Header doc_title={`${title}`} status={status} code={code} />
				{data && <StandardsTable data={standardsData} isLoading={isLoading} error={error} />}
			</div>
		</div>
	);
}

export default DocumentEditor;
