/* http://http://daemonite.github.io/material/docs/4.1/components/input-group/ */

import React from "react";

function Input({
	label,
	label_class_name,
	small_text,
	small_text_id,
	small_text_class_name,
	placeholder,
	type,
	id,
	name,
	required,
	value,
	onChange,
	onBlur,
	hasError,
	input_class_name,
}) {
	return (
		<div className="form-group">
			{label && (
				<label className={`${label_class_name} text`} htmlFor={id}>
					{label}
				</label>
			)}
			<input
				autoComplete="off"
				name={name}
				type={type}
				className={`${input_class_name} input-form form-control  input-w-no-bg  ${hasError && "is-invalid"}`}
				id={id}
				data-testid={id}
				aria-describedby={small_text_id}
				placeholder={placeholder}
				required={required}
				value={value}
				onChange={onChange}
				onBlur={onBlur}
			/>
			{small_text && (
				<small
					id={small_text_id}
					data-testid={small_text_id}
					role="alert"
					className={hasError ? "invalid-feedback" : `${small_text_class_name} form-text muted`}>
					{small_text}
				</small>
			)}
		</div>
	);
}

export default Input;
