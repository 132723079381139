import React from "react";

const Tag = ({ children, className, onClick, remove_button, id }) => {
	return (
		<div className={`chip fade show ${className} my-2 mx-2`} id={id}>
			{children}
			{remove_button && (
				<button className="close" data-dismiss="alert" data-target={`#${id}`} type="button" onClick={onClick}>
					<i className="material-icons">cancel</i>
				</button>
			)}
		</div>
	);
};

export default Tag;
