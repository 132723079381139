/* eslint-disable no-unused-vars */
/* http://daemonite.github.io/material/docs/4.1/material/chips/ */

import React from "react";

function UserProfile({ className, id, imgClassName, user, just_image, just_name, bg_transparent }) {
	const { gradient, name, profile_image_src } = user || {};
	const image = profile_image_src || null;

	return (
		<span id={id} className={`chip ${className} ${(bg_transparent || just_image) && "bg-transparent"} text-capitalize cursor-default`}>
			{just_name ? null : !image ? (
				// <i className="chip-icon material-icons d-flex bg-primary">person</i>
				<i className="chip-icon d-flex" style={{ background: `linear-gradient(${gradient})` }} />
			) : (
				<img alt={`${name}'s profile`} className={`chip-img ${imgClassName ?? "bg-main"}`} src={image} />
			)}
			{!name || just_image ? null : name}
		</span>
	);
}

export default UserProfile;
