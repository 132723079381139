import { useContext } from "react";
import { useMutation, useQueryClient, useInfiniteQuery } from "@tanstack/react-query";
import { findByKey, flattenTree } from "../_helpers/functions";
import { apiBase } from "../_lib/config";
import { NewlyTouchedContext } from "../_lib/contexts/NewlyTouchedContext";
import { useFetchWrapper } from "../_hooks/useFetchWrapper";
import { CompetencyContext, CompetencyRootContext } from "../_lib/contexts";
import { AppConstants } from "../_lib/constants";

// Think about replacing urls with constants

// ReactQuery Hooks
export function useCompetencies(key) {
	const fetchWrapper = useFetchWrapper();
	// eslint-disable-next-line no-unused-vars
	const [rootCompetency, setRootCompetency] = useContext(CompetencyRootContext);
	const { competencies, setCompetencies } = useContext(CompetencyContext);

	const getCompetencies = async (key = "24bc64a6477032a5bc9625a88b0168ca", index = 0) => {
		const url = `${apiBase}/competencies/paginatedChildren/${key}?pageStart=${index}&pageSize=100`;
		let response = await fetchWrapper.get(url);

		if (!Array.isArray(response)) response = [response];
		const root = response.find((c) => c.Key == AppConstants.K12MathRootKey);
		setRootCompetency(root);

		const children = response[0].Children;
		setCompetencies(flattenTree([...competencies, children], true));
		return children;
	};

	return useInfiniteQuery({
		queryKey: [`competencies`],
		queryFn: ({ pageParam }) => getCompetencies(key, pageParam),
		initialPageParam: 0,
		getPreviousPageParam: () => {
			return undefined;
		},
		getNextPageParam: (lastPage, allPages) => {
			return lastPage?.length ? allPages?.length : undefined;
		},
	});
}

export function useAddCompetency() {
	// eslint-disable-next-line no-unused-vars
	const [_competencyState, setNewlyTouched] = useContext(NewlyTouchedContext);
	const queryClient = useQueryClient();
	const fetchWrapper = useFetchWrapper();

	const addCompetency = async (options) => {
		const url = `${apiBase}/competencies`;
		return await fetchWrapper.post(url, options.body, options.headers);
	};

	return useMutation({
		mutationFn: addCompetency,
		onSuccess: async (data) => {
			await queryClient.invalidateQueries({ queryKey: ["competencies"] });
			let compsData = queryClient.getQueryData(["competencies"]);
			let comps = flattenTree(compsData, true);
			let newComp = findByKey(data.Key, comps);
			setNewlyTouched(newComp);
		},
	});
}

export function useDeprecateCompetency() {
	// eslint-disable-next-line no-unused-vars
	const [_competencyState, setNewlyTouched] = useContext(NewlyTouchedContext);
	const queryClient = useQueryClient();
	const fetchWrapper = useFetchWrapper();

	const deprecateCompetency = async (options) => {
		const url = `${apiBase}/competencies/${options.competency_key}/${options.revision}`;
		return await fetchWrapper.delete(url);
	};

	return useMutation({
		mutationFn: deprecateCompetency,
		onSettled: async () => {
			await queryClient.invalidateQueries({ queryKey: ["competencies"] });
			setNewlyTouched(null);
		},
	});
}

export function useEditCompetency() {
	// eslint-disable-next-line no-unused-vars
	const [_competencyState, setNewlyTouched] = useContext(NewlyTouchedContext);
	const queryClient = useQueryClient();
	const fetchWrapper = useFetchWrapper();

	const editCompetency = async (options) => {
		const url = `${apiBase}/competencies/${options.key}/${options.revision}`;
		return {
			result: await fetchWrapper.put(url, options.body, options.headers),
			options,
		};
	};

	return useMutation({
		mutationFn: editCompetency,
		onSuccess: (data) => {
			let comps = queryClient.getQueryData(["competencies"]);
			comps = flattenTree(comps, true);
			let compData = findByKey(data.options.key, comps);
			let newCompData = data.result;
			newCompData.Children = compData.Children;
			newCompData.PeopleId = compData.PeopleId;

			setNewlyTouched(newCompData);
			queryClient.invalidateQueries({ queryKey: ["competencies"] });
		},
	});
}

export function useChangeTransitiveSortOrder() {
	const queryClient = useQueryClient();
	// eslint-disable-next-line no-unused-vars
	const [_competencyState, setNewlyTouched] = useContext(NewlyTouchedContext);
	const fetchWrapper = useFetchWrapper();

	const changeTransitiveSortOrder = async (options) => {
		const url = `${apiBase}/competencies/sort/transitive/${options.TransitiveParentKey}`;
		return { result: await fetchWrapper.patch(url, options.body), options };
	};

	return useMutation({
		mutationFn: changeTransitiveSortOrder,
		onError: () => {
			queryClient.invalidateQueries({ queryKey: ["competencies"] });
		},
		onSuccess: async (data) => {
			await queryClient.invalidateQueries({ queryKey: ["competencies"] });
			let comps = queryClient.getQueryData(["competencies"]);
			comps = flattenTree(comps, true);
			let compData = findByKey(data.options.body[0].CompetencyKey, comps);
			setNewlyTouched(compData);
		},
	});
}
