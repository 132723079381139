import React from "react";
import useTheme from "../../../_hooks/useTheme";
import { Button } from "../../MaterialsUI";

function Header({ doc_title, status, code }) {
	const { isLightTheme } = useTheme();

	return (
		<nav className="d-flex pb-2 position-relative">
			<h5 className={`${isLightTheme && "text-white"} text text-capitalize font-600 tracing-2`}>
				&#123;{code}&#125; {doc_title}
			</h5>
			<Button className="ml-5 btn rounded-input bg-secondary-white text text-capitalize">Properties</Button>
			<Button className="ml-5 btn rounded-input bg-secondary-white text text-capitalize">Delete Document</Button>
			<Button className="ml-5 btn rounded-input bg-secondary-white text text-capitalize">Publish Document</Button>
			<div
				className={`border-rounded bg-muted px-2 font-600 tracing-1 text-base cursor-default position-absolute top-1 right-2 ${
					isLightTheme && "text-white"
				}`}>
				{status}
			</div>
		</nav>
	);
}

export default Header;
