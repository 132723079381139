import { removeValueFromArray, containsCompetency } from "../_helpers/functions";

/* eslint-disable no-case-declarations */
export const competenciesAlignedInitialState = {
	competencies: [],
	editedCompetencies: [],
	addedCompetencies: [],
	removedCompetencies: [],
};

export function competencyAligningReducer(state, action) {
	switch (action.type) {
		case "ADD_ITEM":
			return { ...state, addedCompetencies: [...state.addedCompetencies, action.item] };

		case "EDIT_ADD_ITEM":
			// Handle adding an item when the standard has existing competency alignments (edit mode)
			let newlyAddedCompetencies = [...state.addedCompetencies];
			let removedArr = [...state.removedCompetencies];

			// if the item exists in the "removedCompetencies" array, remove it from that array
			if (containsCompetency(removedArr, action.item)) {
				removedArr = removeValueFromArray(removedArr, action.item);
			}

			// if the item is not already aligned to the standard, add it to the addedCompetencies array
			if (!containsCompetency(state.competencies, action.item)) newlyAddedCompetencies = [...state.addedCompetencies, action.item];

			return {
				...state,
				removedCompetencies: removedArr,
				editedCompetencies: [...state.editedCompetencies, action.item],
				addedCompetencies: newlyAddedCompetencies,
			};

		case "REMOVE_ITEM":
			// Removes item(s) from the "addedCompetencies" array
			let newAlignedItemsArr = [...state.addedCompetencies];

			if (!Array.isArray(action.item)) {
				newAlignedItemsArr = removeValueFromArray(newAlignedItemsArr, action.item);
			} else {
				for (let item of action.item) {
					newAlignedItemsArr = removeValueFromArray(newAlignedItemsArr, item);
				}
			}

			return { ...state, addedCompetencies: newAlignedItemsArr };

		case "EDIT_REMOVE_ITEM":
			// Handle removing an item when the standard has existing competency alignments (edit mode)
			let editNewAlignedItemsArr = [...state.editedCompetencies];
			let editNewAddedCompsArr = [...state.addedCompetencies];
			let removedArray = [...state.removedCompetencies];

			if (!Array.isArray(action.item)) {
				// remove the item(s) from the removed array if it exists in the newly added array already,
				if (editNewAddedCompsArr.includes(action.item)) removedArray = removeValueFromArray(removedArray, action.item);

				// and remove the item(s) from the newly aligned & newly added comps array
				editNewAlignedItemsArr = removeValueFromArray(editNewAlignedItemsArr, action.item);
				editNewAddedCompsArr = removeValueFromArray(editNewAddedCompsArr, action.item);

				// then add the item to the "removed" array
				removedArray = [...removedArray, action.item].filter((v, i, a) => a.indexOf(v) === i);
			} else {
				for (let item of action.item) {
					if (editNewAddedCompsArr.includes(item)) removedArray = removeValueFromArray(removedArray, item);

					editNewAlignedItemsArr = removeValueFromArray(editNewAlignedItemsArr, item);
					editNewAddedCompsArr = removeValueFromArray(editNewAddedCompsArr, item);

					removedArray = removedArray.concat(item).filter((v, i, a) => a.indexOf(v) === i);
				}
			}

			return {
				...state,
				addedCompetencies: editNewAddedCompsArr,
				editedCompetencies: editNewAlignedItemsArr,
				removedCompetencies: removedArray,
			};

		case "SET_ITEMS":
			return { ...state, editedCompetencies: action.item, competencies: action.item };
		default:
			return state;
	}
}
