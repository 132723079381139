import React, { createContext, useReducer } from "react";
import { competenciesAlignedInitialState, competencyAligningReducer } from "../../_reducers/competencyAligningReducer";

const CompetenciesToAlignContext = createContext({
	alignedCompetencies: null,
	addAlignment: () => {},
	setAlignment: () => {},
	removeAlignment: () => {},
});

const CompetenciesToAlignProvider = ({ children }) => {
	const [alignedCompetencies, dispatchAlignment] = useReducer(competencyAligningReducer, competenciesAlignedInitialState);

	/**
	 * Adds the given competency to standard alignment
	 * @param {*} item competency to add to standard alignment
	 * @param {boolean} edit true if form is in "edit" mode, false otherwise
	 */
	function addAlignment(item, edit = false) {
		dispatchAlignment({
			type: edit ? "EDIT_ADD_ITEM" : "ADD_ITEM",
			item,
		});
	}

	/**
	 * Sets the alignedCompetencies context
	 * @param {*} item competencie(s) to set for alignment
	 */
	function setAlignment(item) {
		dispatchAlignment({
			type: "SET_ITEMS",
			item,
		});
	}

	/**
	 * Removes standard alignment of the given competency
	 * @param {*} item competency to remove from standard alignment
	 * @param {boolean} edit true if alignment form is in "edit" mode, false otherwise
	 */
	function removeAlignment(item, edit = false) {
		dispatchAlignment({
			type: edit ? "EDIT_REMOVE_ITEM" : "REMOVE_ITEM",
			item,
		});
	}

	const contextValue = {
		alignedCompetencies,
		addAlignment,
		setAlignment,
		removeAlignment,
	};

	return <CompetenciesToAlignContext.Provider value={contextValue}>{children}</CompetenciesToAlignContext.Provider>;
};

export { CompetenciesToAlignContext, CompetenciesToAlignProvider };
