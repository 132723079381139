import { randomNonce, nextAvailableSortOrder, getTitleFromTuple, getDefinitionFromTuple } from "./functions";

export function isEmpty(input) {
	return (
		input === undefined ||
		input === null ||
		(typeof input === "object" && Object.keys(input).length == 0) ||
		(typeof input === "string" && input.trim().length == 0)
	);
}

export function emailIsValid(email) {
	return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
}

export function validateInput(name, value) {
	let hasError = false,
		error = "";
	switch (name) {
		case "email":
			if (isEmpty(value)) {
				hasError = true;
				error = "Username cannot be empty";
			} else if (!emailIsValid(value)) {
				hasError = true;
				error = "Invalid username 🤨";
			}
			break;
		case "password":
			if (isEmpty(value)) {
				hasError = true;
				error = "Password cannot be empty";
			} else if (value.trim().length < 6) {
				hasError = true;
				error = "Password has at least 6 characters 🦄";
			}
			break;
		case "Title":
			if (isEmpty(value)) {
				hasError = true;
				error = "Title cannot be empty";
			}
			break;
		case "Definition":
			if (isEmpty(value)) {
				hasError = true;
				error = "Definition cannot be empty";
			}
			break;
		case "Parent":
			if (isEmpty(value)) {
				hasError = true;
				error = "Must have a parent";
			}
			break;
		case "Tags":
			break;
		case "Note":
			break;
		case "Description":
			if (isEmpty(value)) {
				hasError = true;
				error = "Description cannot be empty";
			}
			break;
		case "BaseStateStandard":
			if (isEmpty(value)) {
				hasError = true;
				error = "Must have a base state standard set";
			}
			break;
		case "Level":
			if (isEmpty(value)) {
				hasError = true;
				error = "Must have an alignment level";
			}
			break;
		default:
			break;
	}
	return { hasError, error };
}

export function handleInputChange(name, value, dispatch, formState) {
	const { hasError, error } = validateInput(name, value);
	let isFormValid = true;

	// Checks for field error state
	for (const key in formState) {
		const field = formState[key];

		if (key == name && hasError) {
			isFormValid = false;
			break;
		} else if (key != name && field.hasError) {
			isFormValid = false;
			break;
		}
	}

	dispatch({
		type: "UPDATE_FORM",
		data: {
			name,
			value,
			hasError,
			error,
			touched: false,
			isFormValid,
		},
	});
}

export function handleFocus(name, value, dispatch, formState) {
	const { hasError, error } = validateInput(name, value);
	let isFormValid = true;
	for (const key in formState) {
		const field = formState[key];
		if (key == name && hasError) {
			isFormValid = false;
			break;
		} else if (key != name && field.hasError) {
			isFormValid = false;
			break;
		}
	}

	dispatch({
		type: "UPDATE_FORM",
		data: { name, value, hasError, error, touched: true, isFormValid },
	});
}

export function removeNullsInObject(obj) {
	// eslint-disable-next-line no-unused-vars
	return Object.fromEntries(Object.entries(obj).filter(([_, v]) => v !== null));
}

export function sanitizeCompetencyData(formData, current_data, userName, isEdit = false, isCopy = false) {
	// Pass the Title and Definition as a full object with ID too or use current Data somehow?
	const TitleText = formData.Title.value.toLowerCase().replace(/\s+/g, " ").trim(); //Currently only text
	const DefinitionText = formData.Definition.value.replace(/\s+/g, " ").trim(); //Currently only Text
	const AlternateLabel = null;
	const Language = "en-US";

	const uuidv4 = randomNonce(30);
	// const uuidv4 = window.crypto.randomUUID();

	let Status = "PUBLISHED";
	let TitleId = null;
	let DefinitionId = null;
	let Creator = null;
	let NotesData = formData.Note.value;
	let existingNotesData = null;
	let TagsData = formData.Tags.value;
	let existingTags = TagsData;
	let TransitiveParentKey = formData.Parent.value?.Key?.trim();
	// let TransitiveParentLabel = "transitive";
	let TransitiveSortOrder = null;
	let Token = null;

	if (!isEdit) {
		Token = TitleText.replace(/\s+/g, " ")
			.replace(/[^a-zA-Z0-9 ]/g, "")
			.trim()
			.replace(/ +/g, "-");
		if (isCopy) Token = `${Token}-${uuidv4}`;
		Creator = userName;
		TransitiveSortOrder = nextAvailableSortOrder(formData.Parent.value);
	}

	if (isEdit) {
		if (TitleText == getTitleFromTuple(current_data)?.Text) TitleId = getTitleFromTuple(current_data)?.Id;
		if (DefinitionText == getDefinitionFromTuple(current_data).Text) DefinitionId = getDefinitionFromTuple(current_data).Id;
		existingNotesData = current_data.Notes;
		Creator = current_data.Creator;
		Token = current_data.Token;
		TransitiveSortOrder = formData.Parent.value.Children.some((c) => c.Key == current_data.Key)
			? current_data.TransitiveSortOrder
			: nextAvailableSortOrder(formData.Parent.value);
	}

	if (!Array.isArray(NotesData)) NotesData = [NotesData];

	// Checks Optional Competency Data is null or present
	let Notes =
		NotesData[0] && NotesData?.length
			? NotesData.map((note) =>
					Object.assign(
						{},
						{
							Text: note.trim(),
							UserId: userName,
						}
					)
				)
			: [];

	if (isEdit && existingNotesData) Notes = Notes.concat(existingNotesData);
	if ((!NotesData && !existingNotesData) || Notes.length == 0) Notes = null;

	//Filter existing tags and new tags
	if (TagsData && Array.isArray(TagsData)) {
		// eslint-disable-next-line eqeqeq
		TagsData = TagsData.filter((el) => typeof el == "string");
		existingTags = existingTags.filter((el) => typeof el != "string");
	}

	// create an object for each string in an array
	let Tags =
		TagsData && TagsData?.length
			? TagsData.map((tag) =>
					Object.assign(
						{},
						{
							Language,
							Public: true,
							Text: tag.toLowerCase().trim(),
						}
					)
				)
			: [];
	if (existingTags) Tags = Tags.concat(existingTags);

	if (!TagsData || Tags.length == 0) Tags = null;

	let Title = [
		{
			Language,
			Text: TitleText,
			Id: TitleId,
		},
	];

	let Definition = [
		{
			Language,
			Text: DefinitionText,
			Id: DefinitionId,
		},
	];

	Title = [removeNullsInObject(Title[0])];
	Definition = [removeNullsInObject(Definition[0])];

	let newCompetency = {
		Title,
		AlternateLabel,
		Creator,
		Notes,
		Tags,
		Token,
		Definition,
		Status,
		TransitiveParentKey,
		TransitiveSortOrder,
	};

	newCompetency = removeNullsInObject(newCompetency);

	return newCompetency;
}

export function getOptionsFromTags(options, tags) {
	if (Array.isArray(tags) && Array.isArray(options)) {
		tags?.map((option, index) => {
			options.push({
				key: { index },
				label: option.Text ? option.Text : option,
				value: option,
			});
		});
	}
	
}

export function sanitizeStandards(formData, user) {
	const TitleText = formData.Title.value.toLowerCase().replace(/\s+/g, " ").trim(),
		DescriptionText = formData.Description.value.replace(/\s+/g, " ").trim(),
		MaxAlignmentIndex = formData.Level.value + 1,
		Creator = user,
		Language = "en-US",
		Status = "DRAFT";
	let StandardSetId = formData.BaseStateStandard.value?.Code;
	let FrameworkId = formData.BaseStateStandard.value?.FrameworkId;

	let Title = [
		{
			Language,
			Text: TitleText,
		},
	];

	let Description = [
		{
			Language,
			Text: DescriptionText,
		},
	];

	let newStandardAlignment = {
		StandardSetId,
		FrameworkId,
		Creator,
		Title,
		Description,
		MaxAlignmentIndex,
		Status,
	};

	newStandardAlignment = removeNullsInObject(newStandardAlignment);

	return newStandardAlignment;
}

export function sanitizeCompetencyAlignment(standard, competencies) {
	const StandardCode = standard.Path;
	const StandardVersion = standard.Version;
	let CompetencyKeys = [];

	competencies &&
		competencies.map((comp) => {
			CompetencyKeys.push(comp.Key);
		});

	let alignment = {
		StandardCode,
		StandardVersion,
		CompetencyKeys,
	};

	return alignment;
}

export function sanitizeTSOs(newTSOArray) {
	let sanitizedData = [];

	if (!Array.isArray(newTSOArray)) return;

	for (let tso of newTSOArray) {
		const { Key, Revision, TransitiveSortOrder } = tso;

		let newTSOData = {
			CompetencyKey: Key,
			CompetencyRevision: Revision,
			TransitiveSortOrder,
		};

		sanitizedData.push(newTSOData);
	}

	return sanitizedData;
}
