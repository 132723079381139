import React from "react";
import useAuth from "../../../_hooks/useAuth";
// import DefaultNavBar from "./DefaultNavbar";
import LoginNavBar from "./LoginNavBar";
import UserNavBar from "./UserNavbar";
function NavBar() {
	const { currentUser } = useAuth();

	return <nav className="sticky-top">{!currentUser ? <LoginNavBar /> : <UserNavBar />}</nav>;
}

export default React.memo(NavBar);
