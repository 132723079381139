import React, { useEffect } from "react";
import { LoadingCircle } from "../_components/MaterialsUI";
import { LessonHeader, Navbar } from "../_components/UI";
import { cleanClassNames } from "../_helpers/functions";
import useTheme from "../_hooks/useTheme";

function Layout({ children, is_lesson, page_name }) {
	const { isSunsetTheme } = useTheme();

	useEffect(() => {
		cleanClassNames();
	});

	return (
		<main className={`min-h-screen ${!isSunsetTheme ? "background" : "sunset-background"}`}>
			<Navbar />
			{is_lesson && <LessonHeader page_name={page_name} />}
			<div className="container-fluid mx-1 w-auto pt-3">
				<React.Suspense fallback={<LoadingCircle />}>
					<div className="row">{children}</div>
				</React.Suspense>
			</div>
			{/* Footer */}
		</main>
	);
}

export default Layout;
