import React, { useState, forwardRef } from "react";

//Refactor to allow a callback to be passed in for the handleInputChange dispatch

// eslint-disable-next-line react/display-name
const GrowingTextBox = forwardRef(
	(
		{
			initial_rows,
			placeholder,
			className,
			label_className,
			id,
			value,
			defaultValue,
			required,
			label,
			small_text_id,
			small_text,
			hasError,
			onBlur,
			onFocus,
			onChange,
		},
		ref
	) => {
		const [maxRow, setMaxRow] = useState(15);
		const [rows, setRows] = useState(initial_rows);
		function handleGrow(e) {
			const textAreaLineHeight = 24;
			const prevRows = e.target.rows;
			e.target.rows = rows;

			const currentRows = ~~(e.target.scrollHeight / textAreaLineHeight);

			if (currentRows == prevRows) {
				e.target.rows = currentRows;
			}

			if (currentRows >= maxRow) {
				setMaxRow(e.target.rows);
				e.target.scrollTop = e.target.scrollHeight;
			}

			setRows(currentRows < maxRow ? currentRows : maxRow);
			// setValue(e.target.value);
		}

		return (
			<div className="d-flex flex-column w-full">
				{label && (
					<label className={label_className} htmlFor={id}>
						{label}
					</label>
				)}
				<textarea
					id={id}
					ref={ref}
					data-testid={id}
					aria-describedby={small_text_id}
					rows={rows}
					value={value}
					defaultValue={defaultValue}
					onChange={() => {
						handleGrow;
						onChange;
					}}
					onBlur={onBlur}
					onFocus={onFocus}
					className={`w-full ${hasError && "is-invalid"} border-rounded-sm ${className} `}
					placeholder={placeholder}
					required={required}
				/>
				{small_text && (
					<small
						id={small_text_id}
						data-testid={small_text_id}
						role="alert"
						className={hasError ? "invalid-feedback" : "form-text muted"}>
						{small_text}
					</small>
				)}
			</div>
		);
	}
);

export default GrowingTextBox;
