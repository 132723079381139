/* http://daemonite.github.io/material/docs/4.1/components/buttons/ */

import React from "react";

function Button({
	children,
	className,
	id,
	type,
	float,
	material_icons,
	disabled,
	onClick,
	onKeyPress,
	drop_down,
	toggler,
	toggler_to_id,
	data_type,
	expanded,
	data_testid,
}) {
	return (
		<button
			disabled={disabled}
			id={id}
			data-testid={data_testid}
			className={`btn ${className} ${float && "btn-float"} ${drop_down && "dropdown-toggle"}`}
			type={type || "button"}
			data-type={data_type}
			data-toggle={drop_down ? "dropdown" : toggler ? "navdrawer" : null}
			data-target={`#${toggler_to_id}`}
			aria-controls={toggler_to_id}
			aria-expanded={expanded ? true : false}
			aria-haspopup={drop_down ? true : false}
			onKeyPress={onKeyPress}
			onClick={onClick}>
			{float ? <i className="material-icons">{material_icons || "add"}</i> : children}
		</button>
	);
}

export default Button;
