import React, { useContext, useState } from "react";
import Select from "react-select";
import { getTitleFromTuple } from "../../../_helpers/functions";
import { useStandardsAlignments } from "../../../_hooks/useStandardsAlignment";
import useTheme from "../../../_hooks/useTheme";
import { StandardsAlignmentDocumentContext } from "../../../_lib/contexts/StandardsDocumentContext";
import { Modal } from "../../MaterialsUI";
import NewAlignmentDocumentModal from "../NewAlignmentDocumentModal";
import { SelectTheme, DropdownIndicator } from "./SelectStyle";

function LessonHeaderStandardAlignmentBtns() {
	const [currentDocument, setCurrentDocument] = useContext(StandardsAlignmentDocumentContext);
	const { data: alignmentDocData } = useStandardsAlignments();
	const [showNewAlignmentDocumentModal, setShowNewAlignmentDocumentModal] = useState(false);
	const { isNightTheme } = useTheme();

	let standardsDocOptions = [];
	alignmentDocData
		? alignmentDocData.map((option, index) => {
				standardsDocOptions.push({
					key: { index },
					label: getTitleFromTuple(option).Text,
					value: option,
				});
			})
		: null;

	return (
		<>
			<button
				className="btn-border pointer bg-color-purple btn rounded-input text-capitalize shadow-1 ml-5 border-none px-3 text-white"
				onClick={() => setShowNewAlignmentDocumentModal(!showNewAlignmentDocumentModal)}>
				New Alignment Document
			</button>
			<Select
				className="ml-5 text-capitalize"
				inputId="base_state_standard_selector"
				aria-labelledby="base-state-standard-selector"
				placeholder={!alignmentDocData ? "No Alignment Documents Available" : "Select Alignment Document"}
				isSearchable
				menuShouldBlockScroll
				components={{ DropdownIndicator }}
				options={standardsDocOptions}
				// If no error
				value={standardsDocOptions.find((option) => option.value.Key === currentDocument?.Key)}
				styles={SelectTheme(isNightTheme)}
				onChange={(e) => setCurrentDocument(e.value)}
			/>
			<Modal show={showNewAlignmentDocumentModal}>
				<NewAlignmentDocumentModal show={showNewAlignmentDocumentModal} setShow={setShowNewAlignmentDocumentModal} />
			</Modal>
		</>
	);
}

export default LessonHeaderStandardAlignmentBtns;
